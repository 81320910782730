import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import nurturespeaking from "assets/img/nurturespeaking.png";
import location from "assets/img/location.png";
import nurturedoc1 from "assets/img/nurturedoc1.png";
import nurturedoc2 from "assets/img/nurturedoc2.png";
import nurturedoc3 from "assets/img/nurturedoc3.jpg";
import nurturedoc4 from "assets/img/nurturedoc4.jpg";
import nurturedoc5 from "assets/img/nurturedoc5.jpg";
import CancellationPolicy from "./CancellationPolicy";
import Modal from "react-modal";
import docicon1 from "assets/img/docicon1.png";
import docicon2 from "assets/img/docicon2.png";
import docicon3 from "assets/img/docicon3.png";
import docicon4 from "assets/img/docicon4.png";
import meetdoctor from "assets/img/meet-doctor.png";
import Register from "views/auth/Register";
import PrivacyPolicy from "./PrivacyPolicy";
import Login from "views/auth/Login"; // Ensure this import is correct
import doctor2 from "assets/img/Doctor-002.png";
import drvijetha from "assets/img/Dr-vijetha-prasad.png";
import drsrinivas from "assets/img/dr-sreenivas.png";
import doctor1 from "assets/img/Doctor-001.png";
import mendoctor from "assets/img/men-doctor.png";
import womendoctor from "assets/img/women-doctor.png";
import rekha from "assets/img/Rekha-Gogi.png";
import rajeshwari from "assets/img/Rajeshwari-dr.png"
import vishnu from "assets/img/vishnuvarthan-dr.png";
import vignesh from "assets/img/Vignesh-dr.png";
import doctorvijetha from "assets/img/doctorvijetha.png";

export default function OurTeam() {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  const [buttonPopup, setButtonPopup] = useState(false);
  const [buttonPopup1, setButtonPopup1] = useState(false);
  const [buttonPopup2, setButtonPopup2] = useState(false);
  const [buttonPopup3, setButtonPopup3] = useState(false);
  const [buttonPopup4, setButtonPopup4] = useState(false);
  const [buttonPopup5, setButtonPopup5] = useState(false);
  const [buttonPopup6, setButtonPopup6] = useState(false);
  const [buttonPopup7, setButtonPopup7] = useState(false);
  const [buttonPopup8, setButtonPopup8] = useState(false);
  const [buttonPopup9, setButtonPopup9] = useState(false);
  const [buttonPopup10, setButtonPopup10] = useState(false);
  const [buttonPopup11, setButtonPopup11] = useState(false);
  const [buttonPopup12, setButtonPopup12] = useState(false);
  const [buttonPopup13, setButtonPopup13] = useState(false);
  const [buttonPopup14, setButtonPopup14] = useState(false);
  const [buttonPopup15, setButtonPopup15] = useState(false);

  return (
    <>
      <Indexnavbar2 />

      <section className="xl:block lg:block md:block sm:hidden hidden figtree">
        <div>
          <div
            className="xl:h-360 lg:h-380 md:h-380 h-350-px"
            style={{
              backgroundImage: `url(${meetdoctor})`,
              width: "100%",

              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
            }}
          ></div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block figtree -mt-8">
        <div>
          <div
            className="-mb-28"
            style={{
              backgroundImage: `url(${meetdoctor})`,
              width: "100%", // Full width of the container
              height: "400px", // Fixed height for larger screens
              backgroundSize: "contain", // Covers the container while maintaining aspect ratio
              backgroundPosition: "center", // Centers the background image
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
            }}
          ></div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden sm:block block  figtree xl:pb-16 lg:pb-12 pb-12 sofia-pro">
        <div className="container mx-auto xl:px-4 px-4">
          <h1 className="xl:text-4xl  text-iceblue text-center xl:pt-16 lg:pt-12 pt-12 lg:text-3xl text-2xl figtree">
            Meet our <span className="font-bold">expert doctors</span>
          </h1>
          <p className="xl:text-base xl:pt-2 text-center pt-2 text-base text-darknight">
            Expertise you can trust, Meet our esteemed doctors who bring
            exceptional knowledge,<br></br> compassion, and innovation to
            provide top-notch care for your health and well-being.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:mt-12 gap-4 lg:mt-12 mt-8">
            <div className="">
              <div className="bg-5 border-12 border-bottom border-top">
                <div className="flex xl:p-4 justify-between p-4">
                  <div className="icon2 rounded-full">
                    <img
                      className="w-24 rounded-full"
                      src={doctor1}
                      alt="Doctor"
                    />
                  </div>
                  <div className="xl:mt-2 mt-2 flex-grow ml-4">
                    <div className="flex xl:ml-0 ml-2">
                      <div className="border-12 xl:px-2 xl:py-1 px-2 py-1">
                        <p className="text-center text-sm text-grey-600">
                          Consultant
                        </p>
                      </div>
                      <div className="bg-gradient xl:px-4 xl:py-1 xl:ml-4 px-4 py-1 ml-4">
                        <div className="flex">
                          <p className="text-white text-sm">☆</p>
                          <p className="text-white text-sm ml-1">(124)</p>
                        </div>
                      </div>
                    </div>
                    <div className="xl:ml-0 ml-2 lg:ml-0">
                      <h1 className="xl:text-xl font-bold text-iceblue xl:pt-4 text-xl lg:text-lg pt-4">
                        Dr. Ambuja Govindaraj
                      </h1>
                      <p className="xl:text-sm text-darknight xl:pt-2 text-sm  pt-2">
                        MBBS, MS (Obstetrics and Gynaecology)
                      </p>
                      <div className="flex xl:mt-4 mt-4">
                        <div>
                          <img
                            className="w-4"
                            src={nurturespeaking}
                            alt="Languages"
                          />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          Kannada, Hindi, English
                        </p>
                      </div>
                      <div className="flex xl:mt-3 mt-2">
                        <div>
                          <img className="w-4" src={location} alt="Location" />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          Rajaji Nagar, Karnataka
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between xl:px-4 lg:px-2 px-2 xl:pb-4 pb-4 gap-2">
                  <button
                    className="xl:text-base bg-newshade32 text-white  text-base rounded-md xl:w-5/12 py-2 w-5/12"
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup7(true)}
                  >
                    View More
                  </button>
                  <PrivacyPolicy
                    trigger={buttonPopup7}
                    setTrigger={setButtonPopup7}
                  >
                    <h2 className="xl:text-2xl font-semibold text-iceblue text-2xl">
                      Dr. Ambuja Govindaraj
                    </h2>
                    <p className="xl:text-base xl:pt-4 text-darknight text-base pt-4">
                      With twenty years of experience in clinical practice of
                      gynecology and obstetrics, Dr. Ambuja Govindaraj has a
                      wealth of knowledge and expertise to offer. In addition to
                      this, she has undergone extensive training in laparoscopy
                      at A.V hospital, Bangalore and infertility at Momsoon
                      Fertility Center, Bangalore. As a member of BSOG,
                      Bangalore, she has participated in numerous conferences
                      and continue to stay up-to-date with the latest
                      advancements in the field
                    </p>
                    <div className="flex mt-4">
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue">
                            <i className="fab fa-facebook"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-linkedin"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-instagram"></i>
                          </button>
                        </a>
                      </div>
                    </div>
                  </PrivacyPolicy>

                  <button
                    className="xl:text-base bg-gradient text-white  text-base rounded-md xl:w-8/12 py-2 w-8/12"
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup5(true)}
                  >
                    Book an Appointment
                  </button>
                </div>
              </div>

              <CancellationPolicy
                trigger={buttonPopup5}
                setTrigger={setButtonPopup5}
              >
                <div>
                  <Register
                    setTrigger={setButtonPopup5}
                    closePopup={closePopup}
                  />
                </div>
              </CancellationPolicy>
            </div>

            <div className="container mx-auto">
              <div className="bg-5 border-12 border-top border-bottom  ">
                <div className="flex xl:p-4 justify-between p-4">
                  <div className="icon2  rounded-full">
                    <img
                      className="w-24 rounded-full object-cover "
                      src={doctor2}
                      alt="Doctor"
                    />
                  </div>
                  <div className=" xl:mt-2 mt-2 flex-grow ml-4">
                    <div className="flex items-center xl:ml-0 ml-2">
                      <div className=" border-12 xl:px-2 xl:py-1 px-2 py-1">
                        <p className="text-center text-sm text-grey-600">
                          Consultant
                        </p>
                      </div>
                      <div className="bg-gradient xl:px-4 xl:py-1 xl:ml-4 px-4 py-1 ml-4">
                        <div className="flex">
                          <p className="text-white text-sm">☆</p>
                          <p className="text-white text-sm ml-1">(124)</p>
                        </div>
                      </div>
                    </div>
                    <div className="xl:ml-0 ml-1">
                      <h1 className="xl:text-xl font-bold text-iceblue xl:pt-4 text-xl pt-4">
                        Dr. Bhagya Shree
                      </h1>
                      <p className="xl:text-sm text-darknight xl:pt-2 text-sm pt-2">
                        MBBS & MD (Paediatrics)
                      </p>
                      <div className="flex items-center xl:mt-4 mt-4">
                        <div>
                          <img
                            className="w-4"
                            src={nurturespeaking}
                            alt="Languages"
                          />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          Kannada,Hindi,English
                        </p>
                      </div>
                      <div className="flex items-center xl:mt-3 mt-2">
                        <div>
                          <img className="w-4" src={location} alt="Location" />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          Rajaji Nagar, Karnataka
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between  xl:px-4 lg:px-2 px-2 xl:pb-4 pb-4 gap-2 ">
                  <button
                    className="xl:text-base bg-newshade32 text-white xl:w-5/12 w-5/12 py-2 text-base rounded-md lg:px-4"
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup8(true)}
                  >
                    View More
                  </button>
                  <PrivacyPolicy
                    trigger={buttonPopup8}
                    setTrigger={setButtonPopup8}
                  >
                    <h2 className="xl:text-2xl font-semibold text-iceblue text-2xl">
                      Dr. Bhagya Shree
                    </h2>
                    <p className="xl:text-base xl:pt-4 text-darknight text-base pt-4">
                      Paediatrics since last 20 years. She has a vast experience
                      in treating newborns & sick children. She has completed
                      her MBBS & MD (Paediatrics) from prestigious JN Medical
                      college, Belgaum, Karnataka. After completing her
                      post-graduation, she worked in various institutes and was
                      fortunate to have been trained under many stalwarts of
                      Paediatrics. She is very popular with her patients & their
                      parents for her counselling skills and commitment towards
                      patient care.
                    </p>
                    <div className="flex mt-4">
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue">
                            <i className="fab fa-facebook"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-linkedin"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-instagram"></i>
                          </button>
                        </a>
                      </div>
                    </div>
                  </PrivacyPolicy>

                  <button
                    className="xl:text-base bg-gradient text-white xl:w-8/12 w-8/12 py-2 text-base rounded-md "
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup5(true)}
                  >
                    Book an Appointment
                  </button>
                </div>
              </div>
            </div>

            <CancellationPolicy
              trigger={buttonPopup4}
              setTrigger={setButtonPopup4}
            >
              <div>
                <Register
                  setTrigger={setButtonPopup4}
                  closePopup={closePopup}
                />
              </div>
            </CancellationPolicy>

            <div className="container mx-auto">
              <div className="bg-5 border-12 border-top border-bottom">
                <div className="flex xl:p-4 justify-between p-4">
                  <div className="icon2  rounded-full">
                    <img
                      className="w-24 rounded-full object-cover "
                      src={drsrinivas}
                      alt="Doctor"
                    />
                  </div>
                  <div className=" xl:mt-2 mt-2 ml-4 flex-grow">
                    <div className="flex xl:ml-0 ml-2">
                      <div className="border-12 xl:px-2 xl:py-1 px-2 py-1">
                        <p className="text-center text-sm text-darknight">
                          Consultant
                        </p>
                      </div>
                      <div className="bg-gradient xl:px-4 xl:py-1 xl:ml-4 px-4 py-1 ml-4">
                        <div className="flex">
                          <p className="text-white text-sm">☆</p>
                          <p className="text-white text-sm ml-1">(124)</p>
                        </div>
                      </div>
                    </div>
                    <div className="xl:ml-0 ml-2">
                      <h1 className="xl:text-xl font-bold text-iceblue xl:pt-4 text-xl pt-4">
                        Dr. Srinivasa K G
                      </h1>
                      <p className="xl:text-sm text-darknight xl:pt-2 text-sm pt-2">
                        General Surgery, G I surgery, Onco Surgery
                      </p>
                      <div className="flex items-center xl:mt-4 mt-4">
                        <div>
                          <img
                            className="w-4"
                            src={nurturespeaking}
                            alt="Languages"
                          />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          English, Kannada, Hindi,
                        </p>
                      </div>
                      <div className="flex items-center xl:mt-3 mt-2">
                        <div>
                          <img className="w-4" src={location} alt="Location" />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          Rajaji Nagar, Karnataka
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between  xl:px-4 lg:px-2 px-2 xl:pb-4 pb-4 gap-2">
                  <button
                    className="xl:text-base bg-newshade32 text-white xl:w-5/12 w-5/12 py-2 text-base rounded-md  lg:px-4"
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup9(true)}
                  >
                    View More
                  </button>
                  <PrivacyPolicy
                    trigger={buttonPopup9}
                    setTrigger={setButtonPopup9}
                  >
                    <h2 className="xl:text-2xl font-semibold text-iceblue text-2xl">
                      Dr. Srinivasa K G
                    </h2>
                    <p className="xl:text-base xl:pt-4 text-darknight text-base pt-4">
                      MBBS from JJM Medical college, Davanagere, DNB General
                      Surgery from National Board of Examination – New Delhi. He
                      also holds fellowship in Minimal Access Surgery,
                      Fellowship in Hepatol-Biliary (HPB) with liver transplant
                      from Max Hospital New Delhi
                    </p>
                    <div className="flex mt-4">
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue">
                            <i className="fab fa-facebook"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-linkedin"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-instagram"></i>
                          </button>
                        </a>
                      </div>
                    </div>
                  </PrivacyPolicy>

                  <button
                    className="xl:text-base bg-gradient text-white xl:w-8/12 w-8/12 py-2 text-base rounded-md "
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup5(true)}
                  >
                    Book an Appointment
                  </button>
                </div>
              </div>
            </div>

            <CancellationPolicy
              trigger={buttonPopup3}
              setTrigger={setButtonPopup3}
            >
              <div>
                <Register
                  setTrigger={setButtonPopup3}
                  closePopup={closePopup}
                />
              </div>
            </CancellationPolicy>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:mt-4 gap-4 mt-4">
            <div className="container mx-auto">
              <div className="bg-5 border-12 border-top border-bottom">
                <div className="flex xl:p-4 justify-between p-4">
                  <div className="icon2  rounded-full">
                    <img
                      className="w-24 rounded-full object-cover "
                      src={rekha}
                      alt="Doctor"
                    />
                  </div>
                  <div className=" xl:mt-2 mt-2 flex-grow ml-4">
                    <div className="flex xl:ml-0 ml-2">
                      <div className=" border-12 xl:px-2 xl:py-1 px-2 py-1">
                        <p className="text-center text-sm text-darknight">
                          Consultant
                        </p>
                      </div>
                      <div className="bg-gradient xl:px-4 xl:py-1 xl:ml-4 px-4 py-1 ml-4">
                        <div className="flex">
                          <p className="text-white text-sm">☆</p>
                          <p className="text-white text-sm ml-1">(124)</p>
                        </div>
                      </div>
                    </div>
                    <div className="xl:ml-0 ml-2">
                      <h1 className="xl:text-xl font-bold text-iceblue xl:pt-4 pt-4">
                        Dr. Rekha R Gogi
                      </h1>
                      <p className="xl:text-base text-darknight xl:pt-2 text-base pt-2">
                        Obstetrics and Gynecology
                      </p>
                      <div className="flex xl:mt-4 mt-4">
                        <div>
                          <img
                            className="w-4"
                            src={nurturespeaking}
                            alt="Languages"
                          />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          Kannada, Hindi, English, Telgu, Tamil
                        </p>
                      </div>
                      <div className="flex xl:mt-3 mt-2">
                        <div>
                          <img className="w-4" src={location} alt="Location" />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          Rajaji Nagar, Karnataka
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between  xl:px-4 lg:px-2 px-2 xl:pb-4 pb-4 gap-2">
                  <button
                    className="xl:text-base bg-newshade32 text-white  py-2 text-base rounded-md xl:w-5/12 w-5/12 "
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup10(true)}
                  >
                    View More
                  </button>
                  <PrivacyPolicy
                    trigger={buttonPopup10}
                    setTrigger={setButtonPopup10}
                  >
                    <h2 className="xl:text-2xl font-semibold text-iceblue text-2xl">
                      Dr Rekha R Gogi
                    </h2>
                    <p className="xl:text-base xl:pt-4 text-darknight text-base pt-4">
                      I believe as an obgyn for last 20 years , that a healthy
                      woman is happy & healthy family. It is my responsibility
                      to nurture a healthy community by enabling
                      wellness,holistic and preventive health care to women
                      starting at teenage, throughout pregnancy,up to menopause
                      and beyond.
                    </p>
                    <div className="flex mt-4">
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue">
                            <i className="fab fa-facebook"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-linkedin"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-instagram"></i>
                          </button>
                        </a>
                      </div>
                    </div>
                  </PrivacyPolicy>

                  <button
                    className="xl:text-base bg-gradient text-white xl:w-8/12 w-8/12 py-2 text-base rounded-md"
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup5(true)}
                  >
                    Book an Appointment
                  </button>
                </div>
              </div>
            </div>

            <CancellationPolicy
              trigger={buttonPopup2}
              setTrigger={setButtonPopup2}
            >
              <div>
                <Register
                  setTrigger={setButtonPopup2}
                  closePopup={closePopup}
                />
              </div>
            </CancellationPolicy>

            <div className="">
              <div className="bg-5 border-12 border-top border-bottom">
                <div className="flex xl:p-4 justify-between p-4">
                  <div className="icon2  rounded-full">
                    <img
                      className="w-24 rounded-full "
                      src={rajeshwari}
                      alt="Doctor"
                    />
                  </div>
                  <div className=" xl:mt-2 mt-2 flex-grow ml-4">
                    <div className="flex xl:ml-0 ml-2 ">
                      <div className="border-12 xl:px-2 xl:py-1 px-2 py-1">
                        <p className="text-center text-sm text-darknight">
                          consultant
                        </p>
                      </div>
                      <div className="bg-gradient xl:px-4 xl:py-1 xl:ml-4 px-4 py-1 ml-4">
                        <div className="flex">
                          <p className="text-white text-sm">☆</p>
                          <p className="text-white text-sm ml-1">(124)</p>
                        </div>
                      </div>
                    </div>
                    <div className="xl:ml-0 ml-2">
                      <h1 className="xl:text-xl font-bold text-iceblue xl:pt-4 text-xl pt-4">
                        Dr. Rajeshwari S
                      </h1>
                      <p className="xl:text-sm text-darknight xl:pt-2 text-sm pt-2">
                        MBBS & MD (Paediatrics)
                      </p>
                      <div className="flex items-center xl:mt-4 mt-4">
                        <div>
                          <img
                            className="w-4"
                            src={nurturespeaking}
                            alt="Languages"
                          />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          English, Kannada, Hindi, Tamil
                        </p>
                      </div>
                      <div className="flex xl:mt-3 mt-2">
                        <div>
                          <img className="w-4" src={location} alt="Location" />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          Rajaji Nagar, Karnataka
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between  xl:px-4 lg:px-2 px-2 xl:pb-4 pb-4 gap-2">
                  <button
                    className="xl:text-base bg-newshade32 text-white xl:w-5/12 w-5/12 py-2 text-base rounded-md  lg:px-4"
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup11(true)}
                  >
                    View More
                  </button>
                  <PrivacyPolicy
                    trigger={buttonPopup11}
                    setTrigger={setButtonPopup11}
                  >
                    <h2 className="xl:text-2xl font-semibold text-iceblue text-2xl">
                      Dr. Rajeshwari S
                    </h2>
                    <p className="xl:text-base xl:pt-4 text-darknight text-base pt-4">
                      MBBS from MVJ Medical college, Bangalore, and MD in
                      Pediatrics from Vydehi institute of medical sciences,
                      Bangalore. She also holds 6th rank in the Rajiv Gandhi
                      University exams. She holds memberships to the Indian
                      Academy of Pediatrics and Karnataka Medical Council. Dr
                      Rajeshwari S worked on intricate cases in paediatrics and
                      neonatology, across notable tertiary neonatal units like
                      Cloud nine Hospitals.
                    </p>
                    <div className="flex mt-4">
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue">
                            <i className="fab fa-facebook"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-linkedin"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-instagram"></i>
                          </button>
                        </a>
                      </div>
                    </div>
                  </PrivacyPolicy>

                  <button
                    className="xl:text-base bg-gradient text-white xl:w-8/12 w-8/12 py-2 text-base rounded-md "
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup5(true)}
                  >
                    Book an Appointment
                  </button>
                </div>
              </div>
            </div>

            <CancellationPolicy
              trigger={buttonPopup1}
              setTrigger={setButtonPopup1}
            >
              <div>
                <Register
                  setTrigger={setButtonPopup1}
                  closePopup={closePopup}
                />
              </div>
            </CancellationPolicy>

            <div className="">
              <div className="bg-5 border-12 border-top border-bottom">
                <div className="flex xl:p-4 justify-between p-4">
                  <div className="icon2  rounded-full">
                    <img
                      className="w-24 rounded-full "
                      src={doctorvijetha}
                      alt="Doctor"
                    />
                  </div>
                  <div className=" xl:mt-2 mt-2 flex-grow ml-4">
                    <div className="flex xl:ml-0 ml-2">
                      <div className="border-12 xl:px-2 xl:py-1 px-2 py-1">
                        <p className="text-center text-sm text-darknight">
                          Consultant
                        </p>
                      </div>
                      <div className="bg-gradient xl:px-4 xl:py-1 xl:ml-4 px-4 py-1 ml-4">
                        <div className="flex">
                          <p className="text-white text-sm">☆</p>
                          <p className="text-white text-sm ml-1">(124)</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h1 className="xl:text-xl font-bold text-iceblue xl:pt-4 text-xl pt-4">
                        Dr. Vijetha Prasad
                      </h1>
                      <p className="xl:text-sm text-darknight xl:pt-2 text-sm pt-2">
                        Fetal Medicine
                      </p>
                      <div className="flex xl:mt-4 mt-4">
                        <div>
                          <img
                            className="w-4"
                            src={nurturespeaking}
                            alt="Languages"
                          />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          English, Kannada, Hindi
                        </p>
                      </div>
                      <div className="flex xl:mt-3 mt-2">
                        <div>
                          <img className="w-4" src={location} alt="Location" />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          Rajaji Nagar, Karnataka
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between    xl:px-4 lg:px-2 px-2 xl:pb-4 pb-4 gap-2">
                  <button
                    className="xl:text-base bg-newshade32 text-white xl:w-5/12 w-5/12 py-2 text-base rounded-md  lg:px-4"
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup12(true)}
                  >
                    View More
                  </button>
                  <PrivacyPolicy
                    trigger={buttonPopup12}
                    setTrigger={setButtonPopup12}
                  >
                    <h2 className="xl:text-2xl font-semibold text-iceblue text-2xl">
                      Dr. Vijetha Prasad
                    </h2>
                    <p className="xl:text-base xl:pt-4 text-darknight text-base pt-4">
                      MBBS from Vydehi medical College, Bangalore. Gained her
                      post graduate degree in MS OBG from Bharati Vidya peth
                      University (Pune) She holds fellowship in fetal medicine
                      under the mentorship of renowned fetal medicine consultant
                      Dr Adi narayana Makam. Her areas of expertise are NT scan,
                      Detailed anamoly scan, fetal echo and fetal doppler and
                      holds a special interest in intrauterine fetal procedures.
                      She holds a membership in prestigious society of fetal
                      medicine and Karnataka medical council
                    </p>
                    <div className="flex mt-4">
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue">
                            <i className="fab fa-facebook"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-linkedin"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-instagram"></i>
                          </button>
                        </a>
                      </div>
                    </div>
                  </PrivacyPolicy>

                  <button
                    className="xl:text-base bg-gradient text-white xl:w-8/12 w-8/12 py-2 text-base rounded-md "
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup5(true)}
                  >
                    Book an Appointment
                  </button>
                </div>
              </div>
            </div>

            <CancellationPolicy
              trigger={buttonPopup}
              setTrigger={setButtonPopup}
            >
              <div>
                <Register setTrigger={setButtonPopup} closePopup={closePopup} />
              </div>
            </CancellationPolicy>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:mt-4 gap-2 mt-4">
            <div className="">
              <div className="bg-5 border-12 border-top border-bottom">
                <div className="flex xl:p-4 justify-between p-4">
                  <div className="icon2  rounded-full">
                    <img
                      className="w-24 rounded-full "
                      src={vignesh}
                      alt="Doctor"
                    />
                  </div>
                  <div className=" xl:mt-2 mt-2 ml-4 flex-grow">
                    <div className="flex xl:ml-0 ml-2">
                      <div className="border-12 xl:px-2 xl:py-1 px-2 py-1">
                        <p className="text-center text-sm text-darknight">
                          Consultant
                        </p>
                      </div>
                      <div className="bg-gradient xl:px-4 xl:py-1 xl:ml-4 px-4 py-1 ml-4">
                        <div className="flex">
                          <p className="text-white text-sm">☆</p>
                          <p className="text-white text-sm ml-1">(124)</p>
                        </div>
                      </div>
                    </div>
                    <div className="xl:ml-0 ml-2">
                      <h1 className="xl:text-xl font-bold text-iceblue xl:pt-4 text-xl pt-4">
                        Dr. Vignesh Narayan R
                      </h1>
                      <p className="xl:text-sm text-darknight xl:pt-2 text-sm pt-2">
                        Dermatology
                      </p>
                      <div className="flex xl:mt-4 mt-4">
                        <div>
                          <img
                            className="w-4"
                            src={nurturespeaking}
                            alt="Languages"
                          />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          Kannada, Hindi, English
                        </p>
                      </div>
                      <div className="flex xl:mt-3 mt-2">
                        <div>
                          <img className="w-4" src={location} alt="Location" />
                        </div>
                        <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                          Rajaji Nagar, Karnataka
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between  xl:px-4 lg:px-2 px-2 xl:pb-4 pb-4 gap-2">
                  <button
                    className="xl:text-base bg-newshade32 text-white xl:w-5/12 w-5/12 py-2 text-base rounded-md  lg:px-4"
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup13(true)}
                  >
                    View More
                  </button>
                  <PrivacyPolicy
                    trigger={buttonPopup13}
                    setTrigger={setButtonPopup13}
                  >
                    <h2 className="xl:text-2xl font-semibold text-iceblue text-2xl">
                      Dr.Ambuja Govindaraj
                    </h2>
                    <p className="xl:text-base xl:pt-4 text-darknight text-base pt-4">
                      Nurture is a maternity centre with a difference. We
                      believe normal delivery is possible in most of the cases
                      by taking care of fitness and a healthy lifestyle. We will
                      work on these lines by teaching Yoga to expectant mothers
                      and advising a healthy lifestyle in terms of activity,
                      diet, and rest.
                    </p>
                    <div className="flex mt-4">
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue">
                            <i className="fab fa-facebook"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-linkedin"></i>
                          </button>
                        </a>
                      </div>
                      <div>
                        <a href="/">
                          <button className="text-lg text-iceblue ml-2">
                            <i className="fab fa-instagram"></i>
                          </button>
                        </a>
                      </div>
                    </div>
                  </PrivacyPolicy>

                  <button
                    className="xl:text-base bg-gradient text-white xl:w-8/12 w-8/12 py-2 text-base rounded-md "
                    style={{ outline: "none" }}
                    onClick={() => setButtonPopup5(true)}
                  >
                    Book an Appointment
                  </button>
                </div>
              </div>
            </div>

            <CancellationPolicy
              trigger={buttonPopup6}
              setTrigger={setButtonPopup6}
            >
              <div>
                <Register
                  setTrigger={setButtonPopup6}
                  closePopup={closePopup}
                />
              </div>
            </CancellationPolicy>
            <div>
              <div className="container mx-auto">
                <div className="bg-5 border-12 border-top border-bottom">
                  <div className="flex xl:p-4 justify-between p-4">
                    <div className="icon2  rounded-full">
                      <img
                        className="w-24 rounded-full object-cover "
                        src={vishnu}
                        alt="Doctor"
                      />
                    </div>
                    <div className=" xl:mt-2 mt-2 flex-grow ml-4">
                      <div className="flex xl:ml-0 ml-2">
                        <div className=" border-12 xl:px-2 xl:py-1 px-2 py-1">
                          <p className="text-center text-sm text-darknight">
                            Consultant
                          </p>
                        </div>
                        <div className="bg-gradient xl:px-4 xl:py-1 xl:ml-4 px-4 py-1 ml-4">
                          <div className="flex">
                            <p className="text-white text-sm">☆</p>
                            <p className="text-white text-sm ml-1">(124)</p>
                          </div>
                        </div>
                      </div>
                      <div className="xl:ml-0 ml-2">
                        <h1 className="xl:text-xl font-bold text-iceblue xl:pt-4 pt-4">
                          Dr. Vishnuvardhan H.P
                        </h1>
                        <p className="xl:text-base text-darknight xl:pt-2 text-base pt-2">
                          Paediatrics
                        </p>
                        <div className="flex xl:mt-4 mt-4">
                          <div>
                            <img
                              className="w-4"
                              src={nurturespeaking}
                              alt="Languages"
                            />
                          </div>
                          <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                            Kannada, Hindi, English
                          </p>
                        </div>
                        <div className="flex xl:mt-3 mt-2">
                          <div>
                            <img
                              className="w-4"
                              src={location}
                              alt="Location"
                            />
                          </div>
                          <p className="xl:text-sm xl:ml-2 text-sm ml-2 text-darknight">
                            Rajaji Nagar, Karnataka
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between  xl:px-4 lg:px-2 px-2 xl:pb-4 pb-4 gap-2">
                    <button
                      className="xl:text-base bg-newshade32 text-white xl:w-5/12 w-5/12 py-2 text-base rounded-md  lg:px-4"
                      style={{ outline: "none" }}
                      onClick={() => setButtonPopup14(true)}
                    >
                      View More
                    </button>
                    <PrivacyPolicy
                      trigger={buttonPopup14}
                      setTrigger={setButtonPopup14}
                    >
                      <h2 className="xl:text-2xl font-semibold text-iceblue text-2xl">
                        Dr. Vishnuvardhan H.P
                      </h2>
                      <p className="xl:text-base xl:pt-4 text-darknight text-base pt-4">
                        MBBS from S. Nijalingappa medical college Bagalkot,
                        Postgraduation DCH from College of Physicians and
                        Surgeons (CPS) Mumbai. He holds memberships to the
                        Karnataka Medical Council. Dr. Vishnuvardhan H.P worked
                        on intricate cases in paediatrics and neonatology,
                        across notable tertiary neonatal units. He will be
                        available round the clock for Children’s care.
                      </p>
                      <div className="flex mt-4">
                        <div>
                          <a href="/">
                            <button className="text-lg text-iceblue">
                              <i className="fab fa-facebook"></i>
                            </button>
                          </a>
                        </div>
                        <div>
                          <a href="/">
                            <button className="text-lg text-iceblue ml-2">
                              <i className="fab fa-linkedin"></i>
                            </button>
                          </a>
                        </div>
                        <div>
                          <a href="/">
                            <button className="text-lg text-iceblue ml-2">
                              <i className="fab fa-instagram"></i>
                            </button>
                          </a>
                        </div>
                      </div>
                    </PrivacyPolicy>

                    <button
                      className="xl:text-base bg-gradient text-white xl:w-8/12 w-8/12 py-2 text-base rounded-md "
                      style={{ outline: "none" }}
                      onClick={() => setButtonPopup15(true)}
                    >
                      Book an Appointment
                    </button>
                  </div>
                </div>
              </div>

              <CancellationPolicy
                trigger={buttonPopup15}
                setTrigger={setButtonPopup15}
              >
                <div>
                  <Register
                    setTrigger={setButtonPopup15}
                    closePopup={closePopup}
                  />
                </div>
              </CancellationPolicy>
            </div>
          </div>
        </div>
      </section>

      {/*tab view*/}
      <section className="xl:hidden lg:hidden md:block sm:hidden hidden px-4 pb-12 container mx-auto sofia-pro">
        <h1 className=" text-iceblue text-center  pt-12  text-3xl figtree">
          Meet our <span className="font-bold">expert doctors</span>
        </h1>
        <p className=" text-center pt-2 text-base text-darknight">
          Expertise you can trust, Meet our esteemed doctors who bring
          exceptional knowledge,<br></br> compassion, and innovation to provide
          top-notch care for your health and well-being.
        </p>

        <div className="grid grid-cols-2 gap-4 mt-8">
          <div className="">
            <div className="bg-5 border-12 border-bottom border-top ">
              <div className="flex  justify-between p-4">
                <div className="icon2  rounded-full">
                  <img
                    className="w-24 rounded-full "
                    src={doctor1}
                    alt="Doctor"
                  />
                </div>
                <div className="  mt-2">
                  <div className="flex  ml-2">
                    <div className="border-12  px-2 py-1">
                      <p className="text-center text-sm text-darknight ">
                        Consultant
                      </p>
                    </div>
                    <div className="bg-gradient  px-4 py-1 ml-4">
                      <div className="flex">
                        <p className="text-white text-sm">☆</p>
                        <p className="text-white text-sm ml-1">(124)</p>
                      </div>
                    </div>
                  </div>
                  <div className=" ml-2">
                    <h1 className=" font-bold text-iceblue text-xl pt-4">
                      Dr. Ambuja Govindaraj
                    </h1>
                    <p className=" text-darknight  text-sm pt-2">
                      MBBS, MS (Obstetrics and Gynaecology)
                    </p>
                    <div className="flex  mt-4">
                      <div>
                        <img
                          className="w-4"
                          src={nurturespeaking}
                          alt="Languages"
                        />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        Kannada, Hindi, English
                      </p>
                    </div>
                    <div className="flex  mt-2">
                      <div>
                        <img className="w-4" src={location} alt="Location" />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        Rajaji Nagar, Karnataka
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between px-4 pb-4">
                <button
                  className="text-base bg-newshade32 text-white px-8  py-2 text-base rounded-md "
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup7(true)}
                >
                  View More
                </button>
                <PrivacyPolicy
                  trigger={buttonPopup7}
                  setTrigger={setButtonPopup7}
                >
                  <h2 className=" font-semibold text-iceblue text-2xl">
                    Dr. Ambuja Govindaraj
                  </h2>
                  <p className=" text-darknight text-base pt-4">
                    With twenty years of experience in clinical practice of
                    gynecology and obstetrics, Dr. Ambuja Govindaraj has a
                    wealth of knowledge and expertise to offer. In addition to
                    this, she has undergone extensive training in laparoscopy at
                    A.V hospital, Bangalore and infertility at Momsoon Fertility
                    Center, Bangalore. As a member of BSOG, Bangalore, she has
                    participated in numerous conferences and continue to stay
                    up-to-date with the latest advancements in the field
                  </p>
                  <div className="flex mt-4">
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </PrivacyPolicy>

                <button
                  className="xl:text-base bg-gradient text-white xl:px-8 xl:py-2 py-2 text-base rounded-md px-4"
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup5(true)}
                >
                  Book an Appointment
                </button>
              </div>
            </div>
          </div>

          <CancellationPolicy
            trigger={buttonPopup5}
            setTrigger={setButtonPopup5}
          >
            <div>
              <Register setTrigger={setButtonPopup5} closePopup={closePopup} />
            </div>
          </CancellationPolicy>

          <div className="container mx-auto">
            <div className="bg-5 border-12 border-top border-bottom">
              <div className="flex  justify-between p-4">
                <div className="icon2  rounded-full">
                  <img
                    className="w-24 rounded-full "
                    src={doctor2}
                    alt="Doctor"
                  />
                </div>
                <div className="  mt-2 ml-4 flex-grow">
                  <div className="flex  ml-2">
                    <div className="border-12  px-2 py-1">
                      <p className="text-center text-sm text-grey-600">
                        Consultant
                      </p>
                    </div>
                    <div className="bg-gradient  px-4 py-1 ml-4">
                      <div className="flex">
                        <p className="text-white text-sm">☆</p>
                        <p className="text-white text-sm ml-1">(124)</p>
                      </div>
                    </div>
                  </div>
                  <div className=" ml-2">
                    <h1 className=" font-bold text-iceblue  text-xl pt-4">
                      Dr. Bhagya Shree
                    </h1>
                    <p className=" text-darknight text-sm pt-2">
                      MBBS & MD (Paediatrics)
                    </p>
                    <div className="flex  mt-4">
                      <div>
                        <img
                          className="w-4"
                          src={nurturespeaking}
                          alt="Languages"
                        />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        Kannada, Hindi, English
                      </p>
                    </div>
                    <div className="flex  mt-2">
                      <div>
                        <img className="w-4" src={location} alt="Location" />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        Rajaji Nagar, Karnataka
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between px-4 pb-4">
                <button
                  className="text-base bg-newshade32 text-white px-8  py-2 text-base rounded-md "
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup8(true)}
                >
                  View More
                </button>
                <PrivacyPolicy
                  trigger={buttonPopup8}
                  setTrigger={setButtonPopup8}
                >
                  <h2 className=" font-semibold text-iceblue text-2xl">
                    Dr. Bhagya Shree
                  </h2>
                  <p className=" text-darknight text-base pt-4">
                    Paediatrics since last 20 years. She has a vast experience
                    in treating newborns & sick children. She has completed her
                    MBBS & MD (Paediatrics) from prestigious JN Medical college,
                    Belgaum, Karnataka. After completing her post-graduation,
                    she worked in various institutes and was fortunate to have
                    been trained under many stalwarts of Paediatrics. She is
                    very popular with her patients & their parents for her
                    counselling skills and commitment towards patient care.
                  </p>
                  <div className="flex mt-4">
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </PrivacyPolicy>

                <button
                  className="xl:text-base bg-gradient text-white xl:px-8 xl:py-2 py-2 text-base rounded-md px-4"
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup4(true)}
                >
                  Book an Appointment
                </button>
              </div>
            </div>
          </div>

          <CancellationPolicy
            trigger={buttonPopup4}
            setTrigger={setButtonPopup4}
          >
            <div>
              <Register setTrigger={setButtonPopup4} closePopup={closePopup} />
            </div>
          </CancellationPolicy>
        </div>

        <div className="grid grid-cols-2 mt-4 gap-4">
          <div className="">
            <div className="bg-5 border-12 border-top border-bottom">
              <div className="flex  justify-between p-4">
                <div className="icon2  rounded-full">
                  <img
                    className="w-24 rounded-full "
                    src={drsrinivas}
                    alt="Doctor"
                  />
                </div>
                <div className=" mt-2 ml-4 flex-grow">
                  <div className="flex ml-2">
                    <div className="border-12  px-2 py-1">
                      <p className="text-center text-sm text-darknight">
                        Consultant
                      </p>
                    </div>
                    <div className="bg-gradient  px-4 py-1 ml-4">
                      <div className="flex">
                        <p className="text-white text-sm">☆</p>
                        <p className="text-white text-sm ml-1">(124)</p>
                      </div>
                    </div>
                  </div>
                  <div className=" ml-2">
                    <h1 className=" font-bold text-iceblue  text-xl pt-4">
                      Dr. Srinivasa K G
                    </h1>
                    <p className=" text-darknight  text-sm pt-2">
                      General Surgery, G I surgery, Onco Surgery
                    </p>
                    <div className="flex mt-4">
                      <div>
                        <img
                          className="w-4"
                          src={nurturespeaking}
                          alt="Languages"
                        />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        Kannada, Hindi, English
                      </p>
                    </div>
                    <div className="flex  mt-2">
                      <div>
                        <img className="w-4" src={location} alt="Location" />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        Rajaji Nagar, Karnataka
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between px-4 pb-4">
                <button
                  className="text-base bg-newshade32 text-white px-8  py-2 text-base rounded-md "
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup9(true)}
                >
                  View More
                </button>
                <PrivacyPolicy
                  trigger={buttonPopup9}
                  setTrigger={setButtonPopup9}
                >
                  <h2 className=" font-semibold text-iceblue text-2xl">
                    Dr. Srinivasa K G
                  </h2>
                  <p className=" text-darknight text-base pt-4">
                    MBBS from JJM Medical college, Davanagere, DNB General
                    Surgery from National Board of Examination – New Delhi. He
                    also holds fellowship in Minimal Access Surgery, Fellowship
                    in Hepatol-Biliary (HPB) with liver transplant from Max
                    Hospital New Delhi
                  </p>
                  <div className="flex mt-4">
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </PrivacyPolicy>

                <button
                  className="xl:text-base bg-gradient text-white xl:px-8 xl:py-2 py-2 text-base rounded-md px-4"
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup3(true)}
                >
                  Book an Appointment
                </button>
              </div>
            </div>
          </div>

          <CancellationPolicy
            trigger={buttonPopup3}
            setTrigger={setButtonPopup3}
          >
            <div>
              <Register setTrigger={setButtonPopup3} closePopup={closePopup} />
            </div>
          </CancellationPolicy>

          <div className="container mx-auto">
            <div className="bg-5 border-12 border-top border-bottom">
              <div className="flex  justify-between p-4">
                <div className="icon2  rounded-full">
                  <img
                    className="w-24 rounded-full "
                    src={rekha}
                    alt="Doctor"
                  />
                </div>
                <div className="flex-grow ml-4  mt-2">
                  <div className="flex xl:ml-0 ml-2">
                    <div className="border-12  px-2 py-1">
                      <p className="text-center text-sm text-darknight">
                        Consultant
                      </p>
                    </div>
                    <div className="bg-gradient  px-4 py-1 ml-4">
                      <div className="flex">
                        <p className="text-white text-sm">☆</p>
                        <p className="text-white text-sm ml-1">(124)</p>
                      </div>
                    </div>
                  </div>
                  <div className=" ml-2">
                    <h1 className=" font-bold text-iceblue text-xl pt-4">
                      Dr. Rekha R Gogi
                    </h1>
                    <p className=" text-darknight  text-sm pt-2">
                      Obstetrics and Gynecology{" "}
                    </p>
                    <div className="flex  mt-4">
                      <div>
                        <img
                          className="w-4"
                          src={nurturespeaking}
                          alt="Languages"
                        />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        English, Kannada, Telgu
                      </p>
                    </div>
                    <div className="flex  mt-2">
                      <div>
                        <img className="w-4" src={location} alt="Location" />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        Rajaji Nagar, Karnataka
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between px-4 pb-4">
                <button
                  className="text-base bg-newshade32 text-white px-8  py-2 text-base rounded-md "
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup10(true)}
                >
                  View More
                </button>
                <PrivacyPolicy
                  trigger={buttonPopup10}
                  setTrigger={setButtonPopup10}
                >
                  <h2 className=" font-semibold text-iceblue text-2xl">
                    Dr. Rekha R Gogi
                  </h2>
                  <p className=" text-darknight text-base pt-4">
                    I believe as an obgyn for last 20 years , that a healthy
                    woman is happy & healthy family. It is my responsibility To
                    unit of ambuja hospitals a healthy community by enabling wellness,holistic
                    and preventive health care to women starting at teenage,
                    throughout pregnancy,up to menopause and beyond.
                  </p>
                  <div className="flex mt-4">
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </PrivacyPolicy>

                <button
                  className="xl:text-base bg-gradient text-white xl:px-8 xl:py-2 py-2 text-base rounded-md px-4"
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup2(true)}
                >
                  Book an Appointment
                </button>
              </div>
            </div>
          </div>

          <CancellationPolicy
            trigger={buttonPopup2}
            setTrigger={setButtonPopup2}
          >
            <div>
              <Register setTrigger={setButtonPopup2} closePopup={closePopup} />
            </div>
          </CancellationPolicy>
        </div>

        <div className="grid grid-cols-2 mt-4 gap-4">
          <div className="">
            <div className="bg-5 border-12 border-top border-bottom">
              <div className="flex  justify-between p-4">
                <div className="icon2  rounded-full">
                  <img
                    className="w-24 rounded-full "
                    src={rajeshwari}
                    alt="Doctor"
                  />
                </div>
                <div className=" mt-2 flex-grow">
                  <div className="flex  ml-2">
                    <div className="border-12  px-2 py-1">
                      <p className="text-center text-sm text-darknight">
                        Consultant
                      </p>
                    </div>
                    <div className="bg-gradient  px-4 py-1 ml-4">
                      <div className="flex">
                        <p className="text-white text-sm">☆</p>
                        <p className="text-white text-sm ml-1">(124)</p>
                      </div>
                    </div>
                  </div>
                  <div className="ml-2">
                    <h1 className=" font-bold text-iceblue  text-xl pt-4">
                      Dr. Rajeshwari S
                    </h1>
                    <p className=" text-darknight  text-sm pt-2">
                      MBBS & MD (Paediatrics)
                    </p>
                    <div className="flex  mt-4">
                      <div>
                        <img
                          className="w-4"
                          src={nurturespeaking}
                          alt="Languages"
                        />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        English, Kannada, Hindi, Tamil
                      </p>
                    </div>
                    <div className="flex  mt-2">
                      <div>
                        <img className="w-4" src={location} alt="Location" />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        Rajaji Nagar, Karnataka
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between px-4 pb-4">
                <button
                  className="text-base bg-newshade32 text-white px-8  py-2 text-base rounded-md "
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup11(true)}
                >
                  View More
                </button>
                <PrivacyPolicy
                  trigger={buttonPopup11}
                  setTrigger={setButtonPopup11}
                >
                  <h2 className=" font-semibold text-iceblue text-2xl">
                    Dr. Rajeshwari S
                  </h2>
                  <p className=" text-darknight text-base pt-4">
                    MBBS from MVJ Medical college, Bangalore, and MD in
                    Pediatrics from Vydehi institute of medical sciences,
                    Bangalore. She also holds 6th rank in the Rajiv Gandhi
                    University exams. She holds memberships to the Indian
                    Academy of Pediatrics and Karnataka Medical Council. Dr
                    Rajeshwari S worked on intricate cases in paediatrics and
                    neonatology, across notable tertiary neonatal units like
                    Cloud nine Hospitals.
                  </p>
                  <div className="flex mt-4">
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </PrivacyPolicy>

                <button
                  className="xl:text-base bg-gradient text-white xl:px-8 xl:py-2 py-2 text-base rounded-md px-4"
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup1(true)}
                >
                  Book an Appointment
                </button>
              </div>
            </div>
          </div>

          <CancellationPolicy
            trigger={buttonPopup1}
            setTrigger={setButtonPopup1}
          >
            <div>
              <Register setTrigger={setButtonPopup1} closePopup={closePopup} />
            </div>
          </CancellationPolicy>

          <div className="">
            <div className="bg-5 border-12 border-top border-bottom">
              <div className="flex  justify-between p-4">
                <div className="icon2  rounded-full">
                  <img
                    className="w-24 rounded-full "
                    src={doctorvijetha}
                    alt="Doctor"
                  />
                </div>
                <div className="  mt-2 flex-grow ml-4">
                  <div className="flex  ml-2">
                    <div className="border-12  px-2 py-1">
                      <p className="text-center text-sm text-darknight">
                        Consultant
                      </p>
                    </div>
                    <div className="bg-gradient  px-4 py-1 ml-4">
                      <div className="flex">
                        <p className="text-white text-sm">☆</p>
                        <p className="text-white text-sm ml-1">(124)</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h1 className=" font-bold text-iceblue  text-xl pt-4 ml-4">
                      Dr. Vijetha Prasad
                    </h1>
                    <p className=" text-darknight  text-sm pt-2 ml-4">
                      Fetal Medicine
                    </p>
                    <div className="flex  mt-4 ml-4">
                      <div>
                        <img
                          className="w-4"
                          src={nurturespeaking}
                          alt="Languages"
                        />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        English, Kannada, Hindi
                      </p>
                    </div>
                    <div className="flex  mt-2 ml-4">
                      <div>
                        <img className="w-4" src={location} alt="Location" />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        Rajaji Nagar, Karnataka
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between px-4 pb-4">
                <button
                  className="text-base bg-newshade32 text-white px-8  py-2 text-base rounded-md "
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup12(true)}
                >
                  View More
                </button>
                <PrivacyPolicy
                  trigger={buttonPopup12}
                  setTrigger={setButtonPopup12}
                >
                  <h2 className=" font-semibold text-iceblue text-2xl">
                  Dr. Vijetha Prasad
                  </h2>
                  <p className=" text-darknight text-base pt-4">
                    MBBS from Vydehi medical College, Bangalore. Gained her post
                    graduate degree in MS OBG from Bharati Vidya peth University
                    (Pune) She holds fellowship in fetal medicine under the
                    mentorship of renowned fetal medicine consultant Dr Adi
                    narayana Makam. Her areas of expertise are NT scan, Detailed
                    anamoly scan, fetal echo and fetal doppler and holds a
                    special interest in intrauterine fetal procedures. She holds
                    a membership in prestigious society of fetal medicine and
                    Karnataka medical council
                  </p>
                  <div className="flex mt-4">
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </PrivacyPolicy>

                <button
                  className="xl:text-base bg-gradient text-white xl:px-8 xl:py-2 py-2 text-base rounded-md px-4"
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup(true)}
                >
                  Book an Appointment
                </button>
              </div>
            </div>
          </div>

          <CancellationPolicy trigger={buttonPopup} setTrigger={setButtonPopup}>
            <div>
              <Register setTrigger={setButtonPopup} closePopup={closePopup} />
            </div>
          </CancellationPolicy>
        </div>

        <div className="grid grid-cols-2 mt-4 gap-4">
          <div className="">
            <div className="bg-5 border-12 border-top border-bottom">
              <div className="flex justify-between p-4">
                <div className="icon2  rounded-full">
                  <img
                    className="w-24 rounded-full "
                    src={vignesh}
                    alt="Doctor"
                  />
                </div>
                <div className=" mt-2 flex-grow">
                  <div className="flex  ml-2">
                    <div className="border-12  px-2 py-1">
                      <p className="text-center text-sm text-darknight">
                        Consultant
                      </p>
                    </div>
                    <div className="bg-gradient  px-4 py-1 ml-4">
                      <div className="flex">
                        <p className="text-white text-sm">☆</p>
                        <p className="text-white text-sm ml-1">(124)</p>
                      </div>
                    </div>
                  </div>
                  <div className=" ml-2">
                    <h1 className=" font-bold text-iceblue  text-xl pt-4">
                      Dr. Vignesh Narayan R
                    </h1>
                    <p className=" text-darknight  text-sm pt-2">Dermatology</p>
                    <div className="flex  mt-4">
                      <div>
                        <img
                          className="w-4"
                          src={nurturespeaking}
                          alt="Languages"
                        />
                      </div>
                      <p className="text-sm ml-2 text-darknight">
                        Kannada, Hindi, English
                      </p>
                    </div>
                    <div className="flex  mt-2">
                      <div>
                        <img className="w-4" src={location} alt="Location" />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        Rajaji Nagar, Karnataka
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between px-4 pb-4">
                <button
                  className="text-base bg-newshade32 text-white px-8  py-2 text-base rounded-md "
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup13(true)}
                >
                  View More
                </button>
                <PrivacyPolicy
                  trigger={buttonPopup13}
                  setTrigger={setButtonPopup13}
                >
                  <h2 className=" font-semibold text-iceblue text-2xl">
                    Dr. Vignesh Narayan R
                  </h2>
                  <p className=" text-darknight text-base pt-4">
                    MBBS from JIPMER, Puducherry, and his MD in Dermatology,
                    Venereology, and Leprology from the prestigious PGI
                    Chandigarh. Additionally, he holds a DNB certification in
                    the same specialty and has undergone specialized fellowship
                    training in Dermatosurgery at Bangalore Medical College. His
                    professional affiliations include memberships in the Indian
                    Medical Association, IADVL, the Association of Cutaneous
                    Surgeons of India, and the Dermatopathology Society of
                    India. He has also served in pivotal roles such as Convenor
                    and Editor-in-Chief within these organizations.
                  </p>
                  <div className="flex mt-4">
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </PrivacyPolicy>

                <button
                  className="xl:text-base bg-gradient text-white xl:px-8 xl:py-2 py-2 text-base rounded-md px-4"
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup6(true)}
                >
                  Book an Appointment
                </button>
              </div>
            </div>
          </div>

          <CancellationPolicy
            trigger={buttonPopup6}
            setTrigger={setButtonPopup6}
          >
            <div>
              <Register setTrigger={setButtonPopup6} closePopup={closePopup} />
            </div>
          </CancellationPolicy>

          <div className="container mx-auto">
            <div className="bg-5 border-12 border-top border-bottom">
              <div className="flex  justify-between p-4">
                <div className="icon2  rounded-full">
                  <img
                    className="w-24 rounded-full "
                    src={vishnu}
                    alt="Doctor"
                  />
                </div>
                <div className="flex-grow ml-4  mt-2">
                  <div className="flex xl:ml-0 ml-2">
                    <div className="border-12  px-2 py-1">
                      <p className="text-center text-sm text-darknight">
                        Consultant
                      </p>
                    </div>
                    <div className="bg-gradient  px-4 py-1 ml-4">
                      <div className="flex">
                        <p className="text-white text-sm">☆</p>
                        <p className="text-white text-sm ml-1">(124)</p>
                      </div>
                    </div>
                  </div>
                  <div className=" ml-2">
                    <h1 className=" font-bold text-iceblue text-xl pt-4">
                      Dr. Vishnuvardhan H.P
                    </h1>
                    <p className=" text-darknight  text-sm pt-2">Paediatrics</p>
                    <div className="flex  mt-4">
                      <div>
                        <img
                          className="w-4"
                          src={nurturespeaking}
                          alt="Languages"
                        />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        English, Kannada
                      </p>
                    </div>
                    <div className="flex  mt-2">
                      <div>
                        <img className="w-4" src={location} alt="Location" />
                      </div>
                      <p className=" text-sm ml-2 text-darknight">
                        Rajaji Nagar, Karnataka
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between px-4 pb-4">
                <button
                  className="text-base bg-newshade32 text-white px-8  py-2 text-base rounded-md "
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup14(true)}
                >
                  View More
                </button>
                <PrivacyPolicy
                  trigger={buttonPopup14}
                  setTrigger={setButtonPopup14}
                >
                  <h2 className=" font-semibold text-iceblue text-2xl">
                    Dr. Vishnuvardhan H.P
                  </h2>
                  <p className=" text-darknight text-base pt-4">
                    MBBS from S. Nijalingappa medical college Bagalkot,
                    Postgraduation DCH from College of Physicians and Surgeons
                    (CPS) Mumbai. He holds memberships to the Karnataka Medical
                    Council. Dr. Vishnuvardhan H.P worked on intricate cases in
                    paediatrics and neonatology, across notable tertiary
                    neonatal units. He will be available round the clock for
                    Children’s care.
                  </p>
                  <div className="flex mt-4">
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>
                    </div>
                    <div>
                      <a href="/">
                        <button className="text-lg text-iceblue ml-2">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </PrivacyPolicy>

                <button
                  className="xl:text-base bg-gradient text-white xl:px-8 xl:py-2 py-2 text-base rounded-md px-4"
                  style={{ outline: "none" }}
                  onClick={() => setButtonPopup15(true)}
                >
                  Book an Appointment
                </button>
              </div>
            </div>
          </div>

          <CancellationPolicy
            trigger={buttonPopup15}
            setTrigger={setButtonPopup15}
          >
            <div>
              <Register setTrigger={setButtonPopup15} closePopup={closePopup} />
            </div>
          </CancellationPolicy>
        </div>
      </section>
      {/*endd*/}

      <section className="xl:block lg:block md:block sm:block block container mx-auto xl:px-4 xl:pb-16 px-4 lg:pb-16 pb-12 sofia-pro">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-2">
          <div>
            <div className="bg-6 bg-hover1 rounded-md xl:p-4 h-full p-4">
              <div className="flex items-center justify-between h-full">
                <div>
                  <img className="w-16" src={docicon1} alt="Document Icon" />
                </div>

                <div className="flex-grow mx-4 ">
                  <h1 className="xl:text-lgg font-bold text-iceblue text-lg text-hover">
                    Buy Medicines & Essentials
                  </h1>
                  <p className="xl:text-sm text-nightblack pt-1 text-sm">
                    2hrs Delivery
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-6 bg-hover1 rounded-md xl:p-4 h-full p-4">
              <div className="flex items-center justify-between h-full">
                <div>
                  <img className="w-12" src={docicon2} alt="Document Icon" />
                </div>

                <div className="flex-grow mx-4">
                  <h1 className="xl:text-lgg font-bold text-iceblue text-lg text-hover">
                    Doctor Appointment
                  </h1>
                  <p className="xl:text-sm text-nightblack pt-1 text-sm">
                    Book Now
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-6 bg-hover1 rounded-md xl:p-4 h-full p-4">
              <div className="flex items-center justify-between h-full">
                <div>
                  <img className="w-12" src={docicon3} alt="Document Icon" />
                </div>

                <div className="flex-grow mx-4">
                  <h1 className="xl:text-lgg font-bold text-iceblue text-lg text-hover">
                    Lab Tests
                  </h1>
                  <p className="xl:text-sm text-nightblack pt-1 text-sm">
                    At Home
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-6 bg-hover1 rounded-md xl:p-4 h-full p-4">
              <div className="flex items-center justify-between h-full">
                <div>
                  <img className="w-12" src={docicon4} alt="Document Icon" />
                </div>

                <div className="flex-grow mx-4">
                  <h1 className="xl:text-lgg font-bold text-iceblue text-lg text-hover">
                    Insurance
                  </h1>
                  <p className="xl:text-sm text-nightblack pt-1 text-sm">
                    Explore Plans
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{showPopup && <Login closePopup={closePopup} />}</div>
      </section>

      <Footer2 />
    </>
  );
}
