import Footer2 from "components/Footers/Footer2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Profile from "views/Profile";
import doctor1 from "assets/img/Doctor-001.png";
import aboutbg1 from "assets/img/aboutbg1.png";
import award1 from "assets/img/award1.png";
import award2 from "assets/img/award2.png";
import award3 from "assets/img/award3.png";
import treatbefore from "assets/img/treatbefore.jpg";
import abticon1 from "assets/img/abticon1.png";
import abticon2 from "assets/img/abticon2.png";
import abticon3 from "assets/img/abticon3.png";
import nurturebannerr from "assets/img/nurturebannerr.png";
import Login from "views/auth/Login"; // Ensure this import is correct
import ambuja from "assets/img/Dr.Ambuja.webp";
import dctnurture from "assets/img/Dct-nutur.webp";
import siren from "assets/img/siren.png";
import professional from "assets/img/professional.png";

export default function About() {

  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  return (
    <section>
      <Indexnavbar2 />
      <section className="xl:block lg:block md:block sm:hidden hidden figtree">
        <div>
          <div className="xl:h-360 lg:h-380 md:h-380 h-350-px"
            style={{
              backgroundImage: `url(${nurturebannerr})`,
              width: "100%",
              
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
            }}
          ></div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block figtree -mt-8">
        <div>
          <div className="-mb-28"
            style={{
              backgroundImage: `url(${nurturebannerr})`,
              width: "100%", // Full width of the container
              height: "400px", // Fixed height for larger screens
              backgroundSize: "contain", // Covers the container while maintaining aspect ratio
              backgroundPosition: "center", // Centers the background image
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
            }}
          ></div>
        </div>
      </section>

      <section className="xl:block lg:block md:block sm:block block xl:mt-16 container mx-auto xl:px-4 px-4 lg:mt-12 mt-8">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
          <div>
            <p className="sofia-pro font-bold xl:text-lg text-nightblack xl:pt-12 md:text-lg lg:text-lg text-base lg:pt-12 md:pt-8 pt-0 ">
              About us
            </p>
            <h1 className=" figtree xl:text-4xl text-iceblue lg:text-3xl md:text-3xl text-2xl">
              Easy step approach to <br></br>{" "}
              <span className="font-bold">your recovery</span>{" "}
            </h1>
            <p
              className="sofia-pro xl:text-base text-nightblack pt-2 text-base"
              style={{ textAlign: "justify" }}
            >
               Nurture is a maternity centre with a difference. We believe normal
              delivery is possible in most of the cases by taking care of
              fitness and healthy lifestyle. We will work on these lines by
              teaching Yoga to expectant mothers and advising a healthy
              lifestyle in terms of activity, diet and rest. We will work
              together to nurture the mother and baby towards safe delivery,
              natural or assisted as the situation demands. We believe that the
              journey to parenthood should be enjoyable and the baby should
              arrive safe and healthy. We have state of the art delivery rooms
              and operation theatres, High end equipment, Trained Doctors and
              Staff to make the arrival of your Bundle of Joy enjoyable.
            </p>
          </div>

          <div className="xl:ml-6 lg:ml-4 md:ml-4 ml-0">
            <div className="bg-6 rounded-md xl:p-12 lg:p-12 p-6">
              <div className="flex items-start space-x-4 gap-8">
                <div>
                  <h1 className="sofia-pro font-bold transform1 xl:text-4xl font-semibold text-3xl text-iceblue">
                    01
                  </h1>
                </div>
                <div>
                  <h1 className="sofia-pro font-bold xl:text-3xl font-semibold text-iceblue text-2xl xl:pt-2">
                    Our vision
                  </h1>
                </div>
              </div>
              <div className="xl:pl-3 pl-2">
                <p
                  className="sofia-pro xl:text-base text-darknight xl:pt-2 text-base pt-2"
                  style={{ textAlign: "justify" }}
                >
                  To nurture expectant mothers in a holistic manner covering
                  diet, exercise, lifestyle and to ensure positive outcome.
                </p>
                <p
                  className="sofia-pro xl:text-base text-darknight text-base"
                  style={{ textAlign: "justify" }}
                >
                  To ensure complete immunisation and provide comprehensive
                  healthcare for children to ensure their transition into happy
                  and healthy adults.
                </p>
              </div>
            </div>

            <div className="bg-6 rounded-md xl:p-12 xl:mt-4 mt-4 lg:p-12 p-6">
              <div className="flex items-start space-x-4 gap-8">
                <div>
                  <h1 className="sofia-pro font-bold transform1 xl:text-4xl font-semibold text-3xl text-iceblue">
                    02
                  </h1>
                </div>
                <div>
                  <h1 className="sofia-pro font-bold xl:text-3xl font-semibold text-iceblue text-2xl xl:pt-2">
                    Our mission
                  </h1>
                </div>
              </div>

              <div className="xl:pl-3 pl-2">
                <p
                  className=" sofia-pro xl:text-base text-darknight xl:pt-2 text-base pt-2"
                  style={{ textAlign: "justify" }}
                >
                  To nurture expectant mothers to make the journey to motherhood
                  safe and enjoyable
                </p>
                <p
                  className="sofia-pro xl:text-base text-darknight text-base"
                  style={{ textAlign: "justify" }}
                >
                  To provide the best neonatology and paediatrics services to
                  children.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:block sm:hidden hidden  container mx-auto xl:px-4 px-4">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2  ">
          <div className="">
            <h1 className="figtree xl:text-4xl font-semibold text-iceblue xl:pt-24 md:text-3xl  text-2xl lg:pt-12 ">
              Dr.Ambuja Govindaraj
            </h1>
            <h1 className="sofia-pro xl:text-base font-semibold text-darknight xl:pt-2 text-base pt-2">
              MBBS, MS (Obstetrics and Gynaecology)
            </h1>
            <h1 className="sofia-pro xl:text-lg xl:mt-4 text-blackk font-bold text-lg mt-4">
              Past Experience
            </h1>
            <div className="flex xl:mt-4 mt-4">
              <div className="w-5 h-5 rounded-full bg-gradient mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">
                  ✓
                </p>
              </div>
              <p className="sofia-pro xl:text-base font-semibold yellow-on-hover text-darknight xl:ml-2 text-base ml-2">
                Consultant at St. Theresa’s Hospital
              </p>
            </div>
            <div className="flex xl:mt-2 mt-2">
              <div className="w-5 h-5 rounded-full bg-gradient mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">
                  ✓
                </p>
              </div>
              <p className=" sofia-pro xl:text-base font-semibold yellow-on-hover text-darknight xl:ml-2 ml-2 text-base">
                Consultant as Panacea Hospital
              </p>
            </div>
            <div className="flex xl:mt-2 mt-2">
              <div className="w-5 h-5 rounded-full bg-gradient mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">
                  ✓
                </p>
              </div>
              <p className="sofia-pro xl:text-base font-semibold yellow-on-hover text-darknight xl:ml-2 ml-2 text-base">
                Consultant at GM Hospital
              </p>
            </div>
            <div className="flex xl:mt-2 mt-2">
              <div className="w-5 h-5 rounded-full bg-gradient mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">
                  ✓
                </p>
              </div>
              <p className="sofia-pro xl:text-base font-semibold yellow-on-hover text-darknight xl:ml-2 ml-2 text-base">
                Consultant at Columbia Asia Referral Hospital
              </p>
            </div>

            <div className="flex xl:mt-2 mt-2">
              <div className="w-5 h-5 rounded-full bg-gradient mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">
                  ✓
                </p>
              </div>
              <p className="sofia-pro xl:text-base font-semibold yellow-on-hover text-darknight xl:ml-2 ml-2 text-base">
                Consultant at Manipal Hospital
              </p>
            </div>
            <div>
              <button
                className=" sofia-pro bg-gradient rounded-md px-6 py-2 text-white xl:mt-6 xl:text-base mt-4"
                onClick={openPopup}  style={{ outline: "none" }}
              >
                Make an appointment
              </button>
            </div>
          </div>

          <div className=" relative">
            <div className="relative  xl:w-12/12 rounded-xl xl:mt-16 w-12/12 mt-12">
              <div className="center-button">
                <img
                  className="xl:w-12/12 lg:w-12/12 w-12/12 rounded-md"
                  src={ambuja}
                  alt=""
                />
              </div>
              <div className="flex justify-end xl:ml-12 ">
                <div className="absolute  p-4 bg-newshade12 rounded-md z-10 xl:-mt-16  xl:-mr-12 -mt-16 ">
                  <div className="flex items-center">
                    <div className="w-6 h-6 rounded-md bg-newshade16 flex items-center justify-center">
                      <p className="xl:text-base text-white font-bold">✓</p>
                    </div>
                    <p className="sofia-pro xl:text-lg font-semibold text-darknight ml-2">
                      Regular checkup
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:mt-8 mt-8 ">
          <h1 className="sofia-pro xl:text-lg xl:mt-4 text-iceblue font-bold text-lg mt-2">
            About Doctor
          </h1>
          <p
            className="sofia-pro xl:text-base text-darknight text-base"
            style={{ textAlign: "justify" }}
          >
            With twenty years of experience in clinical practice of gynecology
            and obstetrics, Dr. Ambuja Govindaraj has a wealth of knowledge and
            expertise to offer. In addition to this, she has undergone extensive
            training in laparoscopy at A.V hospital, Bangalore and infertility
            at Momsoon Fertility Center, Bangalore. As a member of BSOG,
            Bangalore, she has participated in numerous conferences and continue
            to stay up-to-date with the latest advancements in the field. She
            has excellent communication and interpersonal skills, and has the
            ability to work both independently and as a part of a team. When you
            consult with us directly, you can be assured that you will receive
            the highest quality of care and attention from our experienced and
            skilled professionals.
          </p>
        </div>
        <div>{showPopup && <Login closePopup={closePopup} />}</div>
      </section>


      {/*mobile view ambuja doctor*/}
      <section className="xl:hidden lg:hidden md:hidden sm:block block container mx-auto px-4">
       <div>
       <div className=" relative">
            <div className="relative  rounded-xl  w-12/12 mt-12">
              <div className="center-button">
                <img
                  className=" w-12/12 rounded-md"
                  src={ambuja}
                  alt=""
                />
              </div>
              <div className="flex justify-end  ">
                <div className="absolute  p-4 bg-newshade12 rounded-md z-10  -mt-16 ">
                  <div className="flex items-center">
                    <div className="w-6 h-6 rounded-md bg-newshade16 flex items-center justify-center">
                      <p className="xl:text-base text-white font-bold">✓</p>
                    </div>
                    <p className="sofia-pro xl:text-lg font-semibold text-darknight ml-2">
                      Regular checkup
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
       </div>

       <div className="">
            <h1 className="figtreefont-semibold text-iceblue  text-2xl pt-4">
              Dr.Ambuja Govindaraj
            </h1>
            <h1 className="sofia-pro font-semibold text-darknight text-base pt-2">
              MBBS, MS (Obstetrics and Gynaecology)
            </h1>
            <h1 className="sofia-pro  text-blackk font-bold text-lg mt-4">
              Past Experience
            </h1>
            <div className="flex  mt-4">
              <div className="w-5 h-5 rounded-full bg-gradient mt-11">
                <p className=" text-white text-center font-bold text-sm">
                  ✓
                </p>
              </div>
              <p className="sofia-pro  font-semibold yellow-on-hover text-darknight text-base ml-2">
                Consultant at St. Theresa’s Hospital
              </p>
            </div>
            <div className="flex  mt-2">
              <div className="w-5 h-5 rounded-full bg-gradient mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">
                  ✓
                </p>
              </div>
              <p className=" sofia-pro  font-semibold yellow-on-hover text-darknight  ml-2 text-base">
                Consultant as Panacea Hospital
              </p>
            </div>
            <div className="flex  mt-2">
              <div className="w-5 h-5 rounded-full bg-gradient mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">
                  ✓
                </p>
              </div>
              <p className="sofia-pro xl:text-base font-semibold yellow-on-hover text-darknight  ml-2 text-base">
                Consultant at GM Hospital
              </p>
            </div>
            <div className="flex  mt-2">
              <div className="w-5 h-5 rounded-full bg-gradient mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">
                  ✓
                </p>
              </div>
              <p className="sofia-pro  font-semibold yellow-on-hover text-darknight  ml-2 text-base">
                Consultant at Columbia Asia Referral Hospital
              </p>
            </div>

            <div className="flex mt-2">
              <div className="w-5 h-5 rounded-full bg-gradient mt-11">
                <p className="xl:text-sm text-white text-center font-bold text-sm">
                  ✓
                </p>
              </div>
              <p className="sofia-pro font-semibold yellow-on-hover text-darknight  ml-2 text-base">
                Consultant at Manipal Hospital
              </p>
            </div>
            <div>
              <button
                className=" sofia-pro bg-gradient rounded-md px-6 py-2 text-white  xl:text-base mt-4"
                onClick={openPopup}  style={{ outline: "none" }}
              >
                Make an appointment
              </button>
            </div>
          </div>

          <div className=" mt-6 ">
          <h1 className="sofia-pro  text-iceblue font-bold text-lg mt-2">
            About Doctor
          </h1>
          <p
            className="sofia-pro  text-darknight text-base"
            style={{ textAlign: "justify" }}
          >
            With twenty years of experience in clinical practice of gynecology
            and obstetrics, Dr. Ambuja Govindaraj has a wealth of knowledge and
            expertise to offer. In addition to this, she has undergone extensive
            training in laparoscopy at A.V hospital, Bangalore and infertility
            at Momsoon Fertility Center, Bangalore. As a member of BSOG,
            Bangalore, she has participated in numerous conferences and continue
            to stay up-to-date with the latest advancements in the field. She
            has excellent communication and interpersonal skills, and has the
            ability to work both independently and as a part of a team. When you
            consult with us directly, you can be assured that you will receive
            the highest quality of care and attention from our experienced and
            skilled professionals.
          </p>
        </div>
        <div>{showPopup && <Login closePopup={closePopup} />}</div>
      </section>

      <section
        className="xl:block lg:block md:hidden sm:block block xl:mt-16 bg-new lg:mt-12 mt-12"
        style={{
          backgroundImage: `url(${aboutbg1})`,
        }}
      >
        <div className="container mx-auto xl:px-4 px-4 xl:pt-12 xl:pb-12 lg:pt-12 lg:pb-12 pt-8 pb-8">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 xl:px-32 lg:px-12 md:px-1 px-0 xl:gap-0 lg:gap-0 md:gap-0 gap-4">
            <div>
              <div className="flex justify-center">
                <img
                  class="xl:w-5/12 w-24 img-hover-animate"
                  src={award1}
                  alt=""
                />
              </div>
              <p className="sofia-pro text-white font-semibold xl:text-lg text-center pt-2 lg:text-lg text-base">
                2021 Physiopedia <br></br>superstarBest award
              </p>
            </div>

            <div>
              <div className="flex justify-center">
                <img
                  class="xl:w-5/12 w-24 img-hover-animate"
                  src={award2}
                  alt=""
                />
              </div>
              <p className="sofia-pro text-white font-semibold xl:text-lg text-center pt-2 lg:text-lg text-base">
                2022 Social media<br></br> most impact award
              </p>
            </div>

            <div>
              <div className="flex justify-center">
                <img
                  class="xl:w-5/12 w-24 img-hover-animate"
                  src={award3}
                  alt=""
                />
              </div>
              <p className="sofia-pro text-white font-semibold xl:text-lg text-center pt-2 lg:text-lg text-base">
                2023 Armadale regional<br></br> business awards
              </p>
            </div>

            <div className="xl:pt-16 xl:ml-12 lg:pt-20 lg:ml-12 md:pt-12 xl:block lg:block md:block sm:hidden hidden">
              <div className="">
                <Profile />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*tab view*/}
      <section
        className="xl:hidden lg:hidden md:block sm:hidden hidden  bg-newshade16  mt-12"
        style={{
          backgroundImage: `url(${aboutbg1})`,
        }}
      >
        <div className="container mx-auto  px-4 pt-8 pb-8">
          <div className=" grid grid-cols-4  md:px-1 md:gap-0 ">
            <div>
              <div className="flex justify-center">
                <img class=" w-24 img-hover-animate" src={award1} alt="" />
              </div>
              <p className="text-white font-semibold  text-center pt-2  text-base">
                2021 Physiopedia <br></br>superstarBest award
              </p>
            </div>

            <div>
              <div className="flex justify-center">
                <img class="w-24 img-hover-animate" src={award2} alt="" />
              </div>
              <p className="text-white font-semibold  text-center pt-2  text-base">
                2022 Social media<br></br> most impact award
              </p>
            </div>

            <div>
              <div className="flex justify-center">
                <img class=" w-24 img-hover-animate" src={award3} alt="" />
              </div>
              <p className="text-white font-semibold text-center pt-2 text-base">
                2023 Armadale regional<br></br> business awards
              </p>
            </div>

            <div className=" md:pt-12">
              <div className="">
                <Profile />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-16 container mx-auto xl:px-4 xl:pb-16 lg:mt-12 lg:pb-12 mt-12 pb-12 px-4">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 xl:ml-8">
          <div>
            <div>
              <p className="sofia-pro font-bold xl:text-lg text-grey-600 text-lg">
                Why choose us
              </p>
              <h1 className="figtree xl:text-4xl text-iceblue md:text-3xl text-2xl">
              We are dedicated to<br></br>
                <span className="font-bold"> providing</span>{" "}
              </h1>
              <p className="sofia-pro xl:text-lg text-darknight pt-2 text-base">
              The highest quality healthcare for women and children.<br></br> Here’s why families trust us:
              </p>

              <div className=" xl:mt-8 mt-4">
                <div className="flex items-center ">
                  <div className="icon1 rounded-full bg-5 flex-shrink-0">
                    <div className="center-button">
                      <img className="w-10 pt-3" src={professional} alt="" />
                    </div>
                  </div>

                  <div className="ml-4 flex-grow">
                    <h1 className="sofia-pro xl:text-base font-semibold text-iceblue text-base">
                    Highly Skilled and Compassionate Team
                    </h1>
                    <p className="sofia-pro text-smmm text-darknight">
                    Our team of specialists, including obstetricians, gynecologists,<br></br> pediatricians, and neonatologists, are experts in their fields.
                    </p>
                  </div>
                </div>

                <div className="flex items-center xl:mt-4 mt-4">
                  <div className="icon1 rounded-full  flex-shrink-0">
                    <div className="center-button">
                      <img className="w-10 pt-3" src={siren} alt="" />
                    </div>
                  </div>

                  <div className="ml-4 flex-grow">
                    <h1 className="sofia-pro xl:text-base font-semibold text-iceblue text-base">
                    24/7 Emergency and Critical Care
                    </h1>
                    <p className="sofia-pro text-smmm text-darknight">
                    We provide round-the-clock emergency care, ensuring that expert<br></br> help is always available in urgent situations.
                    </p>
                  </div>
                </div>

                <div className="flex items-center xl:mt-4 mt-4">
                  <div className="icon1 rounded-full  flex-shrink-0">
                    <div className="center-button">
                      <img className="w-9 pt-5" src={abticon3} alt="" />
                    </div>
                  </div>

                  <div className="ml-4 flex-grow">
                    <h1 className="sofia-pro xl:text-base font-semibold text-iceblue text-base">
                    Trusted by Thousands of Families
                    </h1>
                    <p className="sofia-pro text-smmm text-darknight">
                    Over the years, we have earned the trust of countless families<br></br> who continue to choose us for their healthcare needs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <img
                class="xl:w-10/12 lg:w-12/12 md:w-10/12 w-12/12 rounded-md"
                src={dctnurture}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      {/*tabview*/}

      <section className="xl:hidden lg:hidden md:block sm:block block  container mx-auto  mt-12 pb-12 px-4">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  gap-4 ">
          <div>
            <div>
              <p className=" sofia-pro text-grey-600 text-lg">Why choose us</p>
              <h1 className="figtree text-iceblue text-3xl">
              We are dedicated to{" "}
                <span className="font-bold">providing</span>{" "}
              </h1>
              <p className=" text-darknight pt-2 text-base">
              The highest quality healthcare for women and children. Here’s why families trust us:
              </p>

              <div className=" mt-4">
                <div className="flex items-center ">
                  <div className="  rounded-full flex-shrink-0">
                    <div className="center-button">
                      <img className="w-16" src={professional} alt="" />
                    </div>
                  </div>

                  <div className="ml-4 flex-grow">
                    <h1 className="sofia-pro font-semibold text-iceblue text-base">
                    Highly Skilled and Compassionate Team
                    </h1>
                    <p className="sofia-pro text-smmm text-darknight">
                    Our team of specialists, including obstetricians, gynecologists, pediatricians, and neonatologists, are experts in their fields.
                    </p>
                  </div>
                </div>

                <div className="flex items-center  mt-4">
                  <div className=" rounded-full  flex-shrink-0">
                    <div className="center-button">
                      <img className="w-16" src={siren} alt="" />
                    </div>
                  </div>

                  <div className="ml-4 flex-grow">
                    <h1 className="sofia-pro font-semibold text-iceblue text-base">
                    24/7 Emergency and Critical Care
                    </h1>
                    <p className="sofia-pro text-smmm text-darknight">
                    We provide round-the-clock emergency care, ensuring that expert help is always available in urgent situations.
                    </p>
                  </div>
                </div>

                <div className="flex items-center  mt-4">
                  <div className=" rounded-full  flex-shrink-0">
                    <div className="center-button">
                      <img className="w-16" src={abticon3} alt="" />
                    </div>
                  </div>

                  <div className="ml-4 flex-grow">
                    <h1 className="sofia-pro xl:text-base font-semibold text-iceblue text-base">
                    Trusted by Thousands of Families
                    </h1>
                    <p className="sofia-pro text-smmm text-darknight">
                    Over the years, we have earned the trust of countless families who continue to choose us for their healthcare needs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <img
                class="md:w-12/12 w-12/12 md:h-200"
                src={dctnurture}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
    </section>
  );
}
