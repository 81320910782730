import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import nurtureheader1 from "assets/img/nurtureheader1.png";
import wattsupicon from "assets/img/wattsupicon.png";
import phoneicon from "assets/img/phoneicon.png";
import telephone from "assets/img/telephone.png";
import hospicon1 from "assets/img/hospicon1.svg";
import hospicon2 from "assets/img/hospicon2.svg";
import hospicon3 from "assets/img/hospicon3.svg";
import usericon from "assets/img/Sample_User_Icon.png";
import IndexNavbar from "./IndexNavbar";
import instagram from "assets/img/instagramicon1.png";
import Login from "views/auth/Login"; // Ensure this import is correct
import nurturenew from "assets/img/nurturenew.png";
import nurturelogonew from "assets/img/nurturelogonew.png";

export default function Indexnavbar2() {
  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 0 }); // Navigate with state
  };

  const handleClick1 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 16 }); // Navigate with state
  };

  const handleClick2 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 22 }); // Navigate with state
  };

  const handleClick3 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 21 }); // Navigate with state
  };

  const handleClick4 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 13 }); // Navigate with state
  };

  const handleClick5 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 20 }); // Navigate with state
  };

  const handleClick6 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 3 }); // Navigate with state
  };

  const handleClick7 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 19 }); // Navigate with state
  };

  const handleClick8 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 18 }); // Navigate with state
  };

  const handleClick9 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 17 }); // Navigate with state
  };

  const handleClick10 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 12 }); // Navigate with state
  };

  const handleClick11 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 23 }); // Navigate with state
  };

  const handleClick12 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 15 }); // Navigate with state
  };

  const handleClick13 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 24 }); // Navigate with state
  };

  const handleClick14 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 25 }); // Navigate with state
  };

  const handleClick15 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 1 }); // Navigate with state
  };

  const handleClick16 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 2 }); // Navigate with state
  };

  const handleClick17 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 26 }); // Navigate with state
  };

  const handleClick18 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 27 }); // Navigate with state
  };

  const handleClick19 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 28 }); // Navigate with state
  };

  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const [showFirstMenu, setShowFirstMenu] = useState(false);

  const handleFirstMenuClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowFirstMenu(!showFirstMenu);
  };

  const [showFirstMenu1, setShowFirstMenu1] = useState(false);

  const handleFirstMenuClick1 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowFirstMenu1(!showFirstMenu1);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownVisible1, setDropdownVisible1] = useState(false);
  const [showSpecialties, setShowSpecialties] = useState(false);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);
  const toggleDropdown1 = () => setDropdownVisible1(!dropdownVisible1);

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsScrolled(scrollTop > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isHovered, setIsHovered] = useState(false);

  window.addEventListener("scroll", () => {
    const container = document.getElementById("scroll-container");
    if (window.scrollY > 50) {
      container.classList.add("bg-light-pink");
    } else {
      container.classList.remove("bg-light-pink");
    }
  });

  return (
    <section className="">
      <div style={{ position: "relative", zIndex: 999 }}>
        <section
          className={`xl:block lg:block md:hidden sm:hidden hidden  figtree z-50 ${
            isScrolled ? "fixed-on-scroll" : ""
          }`}
        >
          <div className="xl:pt-2  xl:px-4 container mx-auto lg:pt-4  lg:px-4">
            <div className="grid grid-cols-3">
              <div>
                <div className="">
                  <div>
                    <a href="/homepage1">
                      <img
                        className="xl:w-6/12 lg:w-6/12 xl:-mt-2 xl:-ml-4 lg:-mt-1"
                        src={nurturelogonew}
                        alt="nurturenew"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex xl:mt-5 xl:ml-40 lg:mt-4 lg:ml-8 sofia-pro">
                {/*} <a href="/homepage1">
                  <p className="xl:text-lg text-grey-600 yellow-on-hover lg:text-base font-semibold ">
                    Home
                  </p>
                </a>*/}

                <div className="relative inline-block">
                  <div className="relative">
                    <a href="/About">
                      <p
                        className="xl:text-lg text-nightblack yellow-on-hover xl:ml-5 lg:text-base lg:ml-4 cursor-pointer  "
                        onMouseEnter={() => setShowFirstMenu(true)}
                        onMouseLeave={() => setShowFirstMenu(false)}
                      >
                        About
                      </p>
                    </a>
                  </div>
                </div>

                <div className="relative inline-block">
                  <div className="relative">
                    <a href="/Services">
                      <p
                        className="xl:text-lg text-nightblack yellow-on-hover xl:ml-5 lg:text-base lg:ml-4 cursor-pointer"
                        onMouseEnter={() => setShowFirstMenu1(true)}
                      >
                        Specialities
                      </p>
                    </a>
                    {showFirstMenu1 && (
                      <div
                        className="absolute bg-white shadow-lg rounded-lg z-10 text-nightblack width  grid grid-cols-3 gap-0 mt-4 xl:-ml-60 cursor-pointer"
                        onMouseEnter={() => setShowFirstMenu1(true)}
                        onMouseLeave={() => setShowFirstMenu1(false)}
                      >
                        <div className="container mx-auto xl:p-6 lg:p-6 text-base ">
                          <h1 className=" font-bold text-nightblack text-base">
                            Specialities
                          </h1>
                          <a href="/Services" onClick={handleClick1}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Gynaecology
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick2}>
                            <p className="yellow-on-hover text-grey-600 pt-2 ">
                              Adolescent Health
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick3}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Family planning
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Fertility
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick4}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Ultrasonography
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick5}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Fetal Medicine
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick6}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Post Natal care
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick7}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Obstetrics
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick8}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              High Risk Pregnancy
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick9}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              ANC Care
                            </p>
                          </a>
                        </div>

                        <div className="container mx-auto xl:p-6 lg:p-6 text-base">
                          <h1 className=" font-bold text-nightblack"></h1>
                          <a href="/Services" onClick={handleClick10}>
                            <p className="yellow-on-hover text-grey-600 pt-6">
                              Laproscopic Surgeries
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick11}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Well women clinic
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick12}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Neonatology
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick13}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Vaccination
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick14}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              NICU
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick15}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Paediatrics
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick16}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Dermatology
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick17}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Cosmetology
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick18}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              General Surgery
                            </p>
                          </a>

                          <a href="/Services" onClick={handleClick19}>
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Urology and Urogynaecology
                            </p>
                          </a>
                        </div>

                        <div className="container mx-auto xl:p-6 lg:p-6 text-base bg-6">
                          <h1 className=" font-bold text-nightblack">
                            Health Checks
                          </h1>
                          <a href="/">
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Master Health check-up – Female
                            </p>
                          </a>

                          <a href="/">
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Executive Health Check-up - Female
                            </p>
                          </a>

                          <a href="/">
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Pre-Marital Package - Female
                            </p>
                          </a>

                          <a href="/">
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Pre-Conception - Female
                            </p>
                          </a>

                          <a href="/">
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Well Woman Package
                            </p>
                          </a>

                          <a href="/">
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Executive Health Check-up - Male
                            </p>
                          </a>

                          <a href="/">
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Pre-Marital Package - Male
                            </p>
                          </a>

                          <a href="/">
                            <p className="yellow-on-hover text-grey-600 pt-2">
                              Adolescent Health Check-up
                            </p>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="relative inline-block">
                  <div className="relative">
                    <a href="/ourteam">
                      <p
                        className="xl:text-lg text-nightblack yellow-on-hover xl:ml-5 lg:text-base lg:ml-4 cursor-pointer "
                        onMouseEnter={() => setShowFirstMenu(true)}
                        onMouseLeave={() => setShowFirstMenu(false)}
                      >
                        Doctors
                      </p>
                    </a>
                  </div>
                </div>

                <div className="relative inline-block">
                  <a href="/testimonials">
                    <p className="xl:text-lg text-nightblack yellow-on-hover xl:ml-5 lg:text-base lg:ml-4  cursor-pointer">
                      Testimonials
                    </p>
                  </a>
                </div>

                <div className="relative inline-block">
                  <a href="/gallery">
                    <p className="xl:text-lg text-nightblack yellow-on-hover xl:ml-5 lg:text-base lg:ml-4  cursor-pointer">
                      Gallery
                    </p>
                  </a>
                </div>

                <div className="relative inline-block">
                  <a href="/contact?">
                    <p className="xl:text-lg text-nightblack yellow-on-hover xl:ml-5 lg:text-base lg:ml-4  cursor-pointer">
                      Contact
                    </p>
                  </a>
                </div>
              </div>

              <div className="flex justify-end xl:mt-3 lg:mt-2">
                <div className="relative border-6 rounded-full hh-10 ww-10 p-1">
                  <div className="bg-gradient hh-8 ww-8 rounded-full flex justify-center items-center ">
                    <a href="/" onMouseEnter={() => setIsHovered(true)}>
                      <img className="w-4" src={phoneicon} alt="Phone icon" />
                    </a>
                  </div>

                  {/* Ensure the dropdown stays open when hovered */}
                  {isHovered && (
                    <div
                      className="absolute top-12 left-0 bg-white bg-opacity-90 xl:p-8 lg:p-8 border-6 rounded-md shadow-lg z-10 xl:-ml-16  lg:-ml-12 xl:mt-3 lg:mt-2 cursor-pointer"
                      onMouseEnter={() => setIsHovered(true)} // Keep dropdown open when hovering over it
                      onMouseLeave={() => setIsHovered(false)} // Close dropdown when leaving it
                    >
                      <h1 className="xl:text-xl  text-iceblue text-center lg:text-base">
                        Appointments
                      </h1>
                      <hr className="border-blue-50 xl:mt-2 lg:mt-2" />
                      <h1 className="xl:text-base font-semibold text-nightblack  xl:mt-2 lg:text-sm lg:mt-2">
                        Emergency
                      </h1>
                      <div className="flex items-center mt-2">
                        <img
                          className="w-4 mr-2"
                          src={telephone}
                          alt="Phone icon"
                        />
                        <p className="xl:text-sm lg:text-sm">8937503275</p>
                      </div>
                      <h1 className="xl:text-base font-semibold text-nightblack  xl:mt-4 lg:text-sm lg:mt-4">
                        WhatsApp
                      </h1>
                      <div className="flex items-center mt-2">
                        <img
                          className="w-4 mr-2"
                          src={telephone}
                          alt="Phone icon"
                        />
                        <p className="xl:text-sm lg:text-sm">9880009596</p>
                      </div>
                      <h1 className="xl:text-base font-semibold text-nightblack text-center xl:mt-4 lg:mt-4 lg:text-base">
                        Appointment
                      </h1>
                      <hr className="border-blue-50 xl:mt-2 lg:mt-2" />
                      <div className="flex items-center mt-2">
                        <img
                          className="w-4 mr-2"
                          src={telephone}
                          alt="Phone icon"
                        />
                        <p className="xl:text-sm lg:text-sm">080 41226969 </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="border-6 rounded-full p-1 xl:ml-2 hh-10 ww-10 lg:ml-2">
                  <div className="bg-gradient hh-8 ww-8 rounded-full ">
                    <div>
                      <div className="flex justify-center">
                      <a
              href="https://wa.me/919880009596" // WhatsApp URL with the phone number
              target="_blank" >
                          <img class="w-4 pt-11" src={wattsupicon} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-6 rounded-full p-1 xl:ml-2 hh-10 ww-10 lg:ml-2">
                  <div className="bg-gradient hh-8 ww-8 rounded-full ">
                    <div>
                      <div className="flex justify-center">
                      <a
                        href="https://www.instagram.com/nurture.hospital/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                          <img class="w-4 pt-11" src={instagram} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="scroll-container" className="sofia-pro ">
            <hr className="border-blue-50 xl:mt-2 lg:mt-2 "></hr>
            <div className="flex justify-center pt-2 pb-2 ">
              <div className="flex pt-1">
                <div>
                  <img className="w-7" src={hospicon1} alt="Hospital Icon" />
                </div>
                <div>
                  <a
                    href="tel:08041226969"
                    className="xl:text-base text-nightblack cursor-pointer yellow-on-hover lg:text-base ml-1"
                  >
                    Request a Callback
                  </a>
                </div>
              </div>

              <div
                className="bg-newshade13 rounded-full xl:ml-4 p-1 px-2 red-on-hover2 lg:ml-2"
                onClick={openPopup} // Open the popup on click
              >
                <div className="flex">
                  <div>
                    <img className="w-5" src={hospicon2} alt="" />
                  </div>
                  <div>
                    <p className="xl:text-base text-nightblack cursor-pointer yellow-on-hover lg:text-base xl:ml-2 lg:ml-2">
                      Book an Appointment
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex pt-1 xl:ml-4 lg:ml-2">
                <div>
                  <img class="w-7" src={hospicon3} alt="" />
                </div>
                <div>
                  <p className="xl:text-base text-nightblack yellow-on-hover  lg:text-base ml-1 ">
                    Get Health Checkup
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Popup modal for booking an appointment */}
          <div>{showPopup && <Login closePopup={closePopup} />}</div>
        </section>
      </div>

      {/*TAB AND MOBILE VIEW*/}

      <IndexNavbar />
    </section>
  );
}
