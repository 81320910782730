import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import google from "assets/img/google.png";

const Landing = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1100,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: null, // Remove default next arrow
    prevArrow: null, // Remove default prev arrow
  };

  return (
    <section>
      <div className="relative">
        <Slider {...settings}>
          <div>
            <div className="xl:w-11/12 xl:p-6 border-box lg:w-11/12 lg:p-6 md:w-11/12 md:p-6 w-11/12 p-6 h-64 flex flex-col justify-between bg-newshade7 rounded-md">
              <p
                className="sofia-pro xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                style={{ textAlign: "justify" }}
              >
                I got to know about nurture hospital through my gynaecologist,
                Dr Padmapriya. We visited the hospital and was sure to get our
                delivery done here. Hospital is clean, well maintained and
                friendly staff. All medical staff are professional and very
                coperative.
              </p>
              <div className="flex justify-between items-center">
                <div>
                <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                  Vagdevi Ranganatha
                </h4>
                <p className="text-xl text-yellow1 ">★★★★★</p>
                </div>
                <img
                  src={google}
                  className="w-24"
                  alt="Web-Development-Company-Bangalore"
                />
              </div>
            </div>
          </div>

          <div>
            <div className="xl:w-11/12 xl:p-6 lg:w-11/12 lg:p-6 border-box md:w-11/12 md:p-6 w-11/12 p-6 h-64 flex flex-col justify-between bg-newshade7 rounded-md">
              <p
                className="poppins xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                style={{ textAlign: "justify" }}
              >
                We had the best experience and Ambuja mam was super supportive ,
                amenities top class and care the staff gave for 4 days was
                beyond home.
              </p>
              <div className="flex justify-between items-center">
                <div>
                <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                  Shashidhar Hiremath
                </h4>
                <p className="text-xl text-yellow1 ">★★★★★</p>
                </div>
                <img
                  src={google}
                  className="w-24"
                  alt="Web-Development-Company-Bangalore"
                />
              </div>
            </div>
          </div>

          <div>
            <div className="xl:w-11/12 xl:p-6 lg:w-11/12 lg:p-6 border-box md:w-11/12 md:p-6 w-11/12 p-6 h-64 flex flex-col justify-between bg-newshade7 rounded-md">
              <p
                className="sofia-pro xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                style={{ textAlign: "justify" }}
              >
                🙏Thank u Ambuja mam for taking a quick action on my sister's
                condition, during our helpless situation. We can't thank u
                enough ❤️ also not to miss the staffs, they were
                polite,concerned and attentive enough to our sister and to our
                family's emotional situation. Thank u nurture   team 🙏❤️
              </p>
              <div className="flex justify-between items-center">
                <div>
                <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                  Shwetha Gowda
                </h4>
                <p className="text-xl text-yellow1 ">★★★★★</p>
                </div>
                <img
                  src={google}
                  className="w-24"
                  alt="Web-Development-Company-Bangalore"
                />
              </div>
            </div>
          </div>

          <div>
            <div className="xl:w-11/12 xl:p-6 lg:w-11/12 lg:p-6 border-box md:w-11/12 md:p-6 w-11/12 p-6 h-64 flex flex-col justify-between bg-newshade7 rounded-md">
              <p
                className="sofia-pro xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                style={{ textAlign: "justify" }}
              >
                Very well maintained hospital all credit goes to Dr Ambuja mam.
                She is kind, helpful, guide and the way she explains is
                commendable. Nursing staff also very helpful whenever we are in
                need they are ready to help us and the house keeping staff
                maintains the room and hospital neat and clean.
              </p>
              <div className="flex justify-between items-center">
                <div>
                <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                  Vidya M N
                </h4>
                <p className="text-xl text-yellow1 ">★★★★★</p>
                </div>
                <img
                  src={google}
                  className="w-24"
                  alt="Web-Development-Company-Bangalore"
                />
              </div>
            </div>
          </div>


          <div>
            <div className="xl:w-11/12 xl:p-6 lg:w-11/12 lg:p-6 border-box md:w-11/12 md:p-6 w-11/12 p-6 h-64 flex flex-col justify-between bg-newshade7 rounded-md">
              <p
                className="sofia-pro xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                style={{ textAlign: "justify" }}
              >
                Dr.Ambuja Mam She Is A Perfect Blend Of Good Friendly Human Being And A Best Doctor Thank You And Your Team For All The Kind Support And Everything. Very Co-operative Staff And Very Good At Hospital  Service Environment Communication  Exceptional Care With You In Our Corner Our Labor And Delivery  Was Incredible.
              </p>
              <div className="flex justify-between items-center">
                <div>
                <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                Supritha Gowda
                </h4>
                <p className="text-xl text-yellow1 ">★★★★★</p>
                </div>
                <img
                  src={google}
                  className="w-24"
                  alt="Web-Development-Company-Bangalore"
                />
              </div>
            </div>
          </div>


          <div>
            <div className="xl:w-11/12 xl:p-6 lg:w-11/12 lg:p-6 border-box md:w-11/12 md:p-6 w-11/12 p-6 h-64 flex flex-col justify-between bg-newshade7 rounded-md">
              <p
                className="sofia-pro xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                style={{ textAlign: "justify" }}
              >
               I had my normal delivery here. The hospital is clean, well maintained and friendly staff. My gynecologist Dr.Bhanu suggested this hospital and she was very supportive for my delivery. Pediatrician MS.Bhagyashree was too supportive and friendly.
              </p>
              <div className="flex justify-between items-center">
                <div>
                <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                Krupashree 
                </h4>
                <p className="text-xl text-yellow1 ">★★★★★</p>
                </div>
                
                <img
                  src={google}
                  className="w-24"
                  alt="Web-Development-Company-Bangalore"
                />
              </div>
            </div>
          </div>





        </Slider>
      </div>
    </section>
  );
};

export default Landing;
