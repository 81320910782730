import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer2 from "components/Footers/Footer2";
import nurturebg1 from "assets/img/nurturebg1.jpg";
import SliderPage from "views/SliderPage";
import nurturedoc1 from "assets/img/nurturedoc1.png";
import nurturedoc2 from "assets/img/nurturedoc2.png";
import nurturedoc3 from "assets/img/nurturedoc3.png";
import nurturedoc4 from "assets/img/nurturedoc4.png";
import photoroom from "assets/img/_DSC2311-Photoroom.png";
import bag from "assets/img/bag.png";
import doctor1 from "assets/img/Doctor-001.png";
import doctor2 from "assets/img/Doctor-002.png";
import nurturehome9 from "assets/img/nurturehome9.webp";
import nurturehome10 from "assets/img/nurturehome10.webp";
import qrcode from "assets/img/qr-code.png";
import drvijetha from "assets/img/Dr-vijetha-prasad.png";
import drsrinivas from "assets/img/dr-sreenivas.png";
import testimonialnew from "assets/img/testimonialnew.png";
import Form from "components/Dropdowns/Form";
import meetdoctor from "assets/img/meet-doctor.png";
import Cloudslider from "views/Cloudslider";
import Galleryslider from "views/GallerySlider";
import Login from "views/auth/Login";
import Landing from "views/Landing";
import pattrenadd from "assets/img/pattern-for-add.png";
import nurtureqr from "assets/img/Nurtureqr.png";
import doctorebanner from "assets/img/doctor-baner.png";
import phoneapp from "assets/img/phone-app.png";
import rekha from "assets/img/Rekha-Gogi.png";
import rekhapng from "assets/img/rekhapng.png";
import doctorbanner1 from "assets/img/doctorbanner1.png";
import doctorvijetha from "assets/img/doctorvijetha.png";

export default function Homepage1() {
  // Counting numbers
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);
  const sectionRef = useRef(null);
  const startedRef = useRef(false);

  const animateCount = (target, setter, duration) => {
    let start = 0;
    const increment = target / (duration / 10);

    const timer = setInterval(() => {
      start += increment;
      if (start >= target) {
        start = target;
        clearInterval(timer);
      }
      setter(Math.ceil(start));
    }, 10);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !startedRef.current) {
          animateCount(12, setCount1, 2500, true);
          animateCount(10, setCount2, 2500, true);
          animateCount(48, setCount3, 2500, true);
          animateCount(30, setCount4, 2500);
          startedRef.current = true;
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer && sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const videoRef = useRef(null);

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const [showPopup, setShowPopup] = useState(false);

   useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 10000); // 10 seconds in milliseconds

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []); // Empty dependency array ensures this runs on mount

  const closePopup = () => {
    setShowPopup(false);
  };


  return (
    <section>
      <Indexnavbar2 />

      {/* <section className="xl:block lg:block md:block sm:block block">
        <div>
          <div className="App">
            <VideoComponent ss />
          </div>
        </div>
      </section> */}

      <section className="xl:block lg:block md:block sm:block block ">
       <div className="xl:-mt-32 lg:-mt-34">
        <div>
          <Cloudslider/>
        </div>
       </div>
      </section>
      <div>
       
        <section className="xl:block lg:block md:block sm:block block xl:mt-16 container mx-auto xl:px-4 lg:mt-12 lg:px-4 mt-12 px-4">
          <h1 className="xl:text-4xl lg:text-3xl text-iceblue text-center md:text-3xl text-2xl figtree">
            Our specialities <span className="font-bold">for your needs</span>{" "}
          </h1>
          <p className="xl:text-lg text-center xl:pt-2 lg:pt-1 xl:px-32 lg:text-lg md:text-lg text-base sofia-pro text-nightblack">
            Empowering your Journey to Parenthood with our Comprehensive
            Maternity Services
          </p>

           <div className="xl:mt-8 mt-8">
            <div>
             <Galleryslider/>
            </div>
           </div>
        </section>

        <section className="xl:block lg:block md:block sm:hidden hidden  figtree xl:mt-16 xl:pb-16 container mx-auto xl:px-4 lg:mt-12 lg:pb-12 lg:px-4 px-4 mt-12 pb-12">
          <div
            className=" class-5 rounded-lg bg-new xl:p-20 lg:p-12 md:p-8 p-4"
            style={{
              backgroundImage: `url(${pattrenadd})`,
              backgroundSize: "cover", // Ensures the image covers the whole container
              backgroundPosition: "center", // Centers the background image
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
              
              
            }}
          >
            <div className="flex justify-between sofia-pro ">
              <div>
                <h1 className="xl:text-2xl  text-white lg:text-2xl text-xl">
                  All medical records, reports & prescriptions in one place{" "}
                </h1>
                <button className="text-base text-blackk rounded-md px-6 py-2 rounded-md bg-white mt-4">
                  View Reports
                </button>
              </div>
              
            </div>
          </div>
        </section>

        {/*mobile view medical*/}
        <section className="xl:hidden lg:hidden md:hidden sm:block block  figtree container mx-auto   px-4 mt-12 pb-12">
          <div
            className=" class-5 rounded-lg bg-new p-4"
            style={{
              backgroundImage: `url(${pattrenadd})`,
              backgroundSize: "contain", // Ensures the image covers the whole container
              backgroundPosition: "center", // Centers the background image
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
              
              
            }}
          >
            <div className="flex justify-between sofia-pro ">
              <div>
                <h1 className=" text-white  text-xl">
                  All medical records, reports & prescriptions in one place{" "}
                </h1>
                <button className="text-base text-blackk rounded-md px-6 py-2 rounded-md bg-white mt-4">
                  View Reports
                </button>
              </div>
              
            </div>
          </div>
        </section>

        <section className="xl:block lg:block md:block sm:block block container mx-auto xl:px-4 lg:px-4 px-4">
          <h1 className="xl:text-4xl  text-iceblue text-center lg:text-3xl md:text-3xl text-2xl figtree">
            Meet our <span className="font-bold">expert doctors</span>
          </h1>
          <p className="xl:text-lg text-center pt-2 lg:text-lg md:text-lg text-base sofia-pro text-nightblack">
          We are always here for listening to you and understanding your needs
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-4 xl:mt-12 lg:mt-8 mt-8 sofia-pro items-stretch">
            <div className="zoom-effect">
              <div className="bg-1 rounded-lg">
                <div className="center-button">
                  <img class="xl:w-10/12 w-10/12" src={doctor1} alt="" />
                </div>
              </div>
              <div className="bg-white rounded-lg shadow-xl xl:p-4 lg:p-4 p-4">
                <div className="flex justify-between">
                  <div>
                    <h1 className="xl:text-xl font-semibold text-nightblack text-xl">
                      Dr.Ambuja Govindaraj
                    </h1>
                    <p className="xl:text-sm text-grey-600 text-base">
                      Obstetric and Gynaecology
                    </p>
                  </div>
                  
                </div>

                <div className="flex justify-between xl:mt-4 mt-4">
                  <div className="flex mt-1">
                    <div>
                      <img class="w-6" src={bag} alt="" />
                    </div>
                    <p className="xl:text-base text-grey-600 ml-1 text-base">
                      22 years
                    </p>
                  </div>

                  <div className="flex">
                    <p className="text-xl text-grey-600 font-bold">☆</p>
                    <p className="xl:text-base text-grey-600 ml-1 pt-1 text-base">
                      5
                    </p>
                  </div>
                </div>
              </div>
            </div>


            <div className="zoom-effect">
              <div className="bg-2 rounded-lg">
                <div className="center-button">
                  <img class="xl:w-10/12 w-10/12" src={doctor2} alt="" />
                </div>
              </div>
              <div className="bg-white rounded-lg shadow-xl xl:p-4 lg:p-4 p-4">
                <div className="flex justify-between">
                  <div>
                    <h1 className="xl:text-xl font-semibold text-nightblack text-xl">
                    Dr. Bhagya Shree
                    </h1>
                    <p className="xl:text-sm text-grey-600 text-base">
                    Consultant Paediatrician
                    </p>
                  </div>
                  
                </div>

                <div className="flex justify-between xl:mt-4 mt-4">
                  <div className="flex mt-1">
                    <div>
                      <img class="w-6" src={bag} alt="" />
                    </div>
                    <p className="xl:text-base text-grey-600 ml-1 text-base">
                      15 years
                    </p>
                  </div>

                  <div className="flex">
                    <p className="text-xl text-grey-600 font-bold">☆</p>
                    <p className="xl:text-base text-grey-600 ml-1 pt-1 text-base">
                      5
                    </p>
                  </div>
                </div>
              </div>
            </div>












            <div className="zoom-effect ">
              <div className="bg-8 rounded-lg">
                <div className="center-button">
                  <img class="xl:w-10/12 w-10/12" src={rekhapng} alt="" />
                </div>
              </div>
              <div className="bg-white rounded-lg shadow-xl xl:p-4 lg:p-4 p-4">
                <div className="flex justify-between">
                  <div>
                    <h1 className="xl:text-xl font-semibold  text-nightblack text-xl">
                    Dr. Rekha R Gogi
                    </h1>
                    <p className="xl:text-sm text-grey-600 text-base">
                    Obstetrics and Gynecology
                    </p>
                  </div>
                  
                </div>

                <div className="flex justify-between xl:mt-4 mt-4">
                  <div className="flex mt-1">
                    <div>
                      <img class="w-6" src={bag} alt="" />
                    </div>
                    <p className="xl:text-base text-grey-600 ml-1 text-base">
                      20 years
                    </p>
                  </div>

                  <div className="flex">
                    <p className="text-xl text-grey-600 font-bold">☆</p>
                    <p className="xl:text-base text-grey-600 ml-1 pt-1 text-base">
                      5
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="zoom-effect ">
              <div className="bg-3 rounded-lg">
                <div className="center-button">
                  <img class="xl:w-10/12 w-10/12" src={drsrinivas} alt="" />
                </div>
              </div>
              <div className="bg-white rounded-lg shadow-xl xl:p-4 lg:p-4 p-4">
                <div className="flex justify-between">
                  <div>
                    <h1 className="xl:text-xl font-semibold  text-nightblack text-xl">
                    Dr. Srinivasa K G
                    </h1>
                    <p className="xl:text-sm text-grey-600 text-base">
                    General and Onco Surgeon
                    </p>
                  </div>
                 
                </div>

                <div className="flex justify-between xl:mt-4 mt-4">
                  <div className="flex mt-1">
                    <div>
                      <img class="w-6" src={bag} alt="" />
                    </div>
                    <p className="xl:text-base text-grey-600 ml-1 text-base">
                      20 years
                    </p>
                  </div>

                  <div className="flex">
                    <p className="text-xl text-grey-600 font-bold">☆</p>
                    <p className="xl:text-base text-nightblack ml-1 pt-1 text-base">
                      5
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="zoom-effect ">
              <div className="bg-4 rounded-lg">
                <div className="center-button">
                  <img class="xl:w-10/12 w-10/12" src={doctorvijetha} alt="" />
                </div>
              </div>
              <div className="bg-white rounded-lg shadow-xl xl:p-4 lg:p-4 p-4">
                <div className="flex justify-between">
                  <div>
                    <h1 className="xl:text-xl font-semibold  text-nightblack text-xl">
                      Dr.Vijetha Prasad{" "}
                    </h1>
                    <p className="xl:text-sm text-grey-600 text-base">
                      Fetal Medicine Consultant
                    </p>
                  </div>
                  
                </div>

                <div className="flex justify-between xl:mt-4 mt-4">
                  <div className="flex mt-1">
                    <div>
                      <img class="w-6" src={bag} alt="" />
                    </div>
                    <p className="xl:text-base text-grey-600 ml-1 text-base">
                      12 years
                    </p>
                  </div>

                  <div className="flex">
                    <p className="text-xl text-grey-600 font-bold">☆</p>
                    <p className="xl:text-base text-grey-600 ml-1 pt-1 text-base">
                      5
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h1 className="xl:text-2xl text-nightblack xl:mt-12 text-center lg:mt-8 text-2xl mt-8">
              Meet <span className="font-bold">our doctors</span>
            </h1>
            <div className="flex justify-center xl:mt-4 lg:mt-4 mt-4">
              <div className="border-5 rounded-full">
                <img className="w-12 rounded-full" src={nurturedoc2} alt="" />
              </div>
              <div className=" xl:-ml-4 z-10 lg:-ml-4 -ml-4">
                <div className="border-5 rounded-full">
                  <img className="w-12 rounded-full" src={rekha} alt="" />
                </div>
              </div>
              <div className=" xl:-ml-4 z-10 lg:-ml-4 -ml-4">
                <div className="border-5 rounded-full">
                  <img className="w-12 rounded-full" src={nurturedoc4} alt="" />
                </div>
              </div>

              <div className=" xl:-ml-4 z-10 lg:-ml-4 -ml-4">
                <div className="border-5 rounded-full">
                  <img className="w-12 rounded-full" src={nurturedoc3} alt="" />
                </div>
              </div>
              <div className=" xl:-ml-4 z-10 lg:-ml-4 -ml-4">
                <div className="border-5 rounded-full">
                  <img className="w-12 rounded-full" src={doctorvijetha} alt="" />
                </div>
              </div>
              <div className="xl:-ml-4 z-10 mt-1 lg:-ml-4 -ml-4">
                <div className="bg-gradient h-12 w-12 rounded-full border-5">
                  <a href="/ourteam">
                    <span className="text-white xl:text-2xl text-2xl text-center pl-3 pt-2">
                      +
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="xl:block lg:block md:block sm:block block xl:pt-20 xl:pb-16 lg:pb-12 lg:pt-12 pt-12 pb-12">
          <div className="container mx-auto xl:px-4 lg:px-4 px-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 ">
              <div className="">
                <div className="">
                  <h1 className="xl:text-4xl text-iceblue lg:text-3xl md:text-3xl text-2xl figtree">
                 Nurture<br></br>
                    <span className="font-bold mt-2 xl:text-3xl text-xl lg:text-2xl "> A Unit of Ambuja Hospitals<br></br> Private Limited</span>
                  </h1>
                  <p className="xl:text-base xl:pt-4 text-base lg:pt-2 md:pt-2 pt-4 sofia-pro text-nightblack">
                  nurture has a team of renowned and experienced
                    consultants with 50+ clinical departments that cater to
                    various diseases nurture hospitals has a team of renowned
                    and experienced consultants.
                  </p>
                </div>
                <div className="">
                  <div className=" relative  overflow-hidden xl:mt-4 lg:mt-1 md:mt-1 mt-4 sofia-pro">
                    <div className="">
                      <div
                        ref={sectionRef}
                        className="grid grid-cols-3 xl:mr-24 lg:mr-12 mr-2"
                      >
                        <div className="">
                          <h1 className="  font-bold xl:text-6xl lg:text-4xlll text-2xl text-nightblack ">
                            {count1}+<p></p>
                          </h1>
                          <div className="divider"></div>
                          <p className="xl:text-base font-semibold xl:pt-2 text-base text-nightblack">
                            Specialities
                          </p>
                        </div>
                        <div className="center-items xl:ml-0 lg:ml-0 ml-4">
                          <h1 className="  font-bold xl:text-6xl lg:text-4xlll text-2xl  text-nightblack ">
                            {count2}+<p></p>
                          </h1>
                          <div className="divider"></div>
                          <p className="xl:text-base font-semibold xl:pt-2 text-base text-nightblack">
                            Top notch doctors
                          </p>
                        </div>
                        {/* <div className="center-items ">
                          <h1 className="  font-bold xl:text-6xl lg:text-4xlll text-2xl  text-nightblack ">
                            {count3}+<p></p>
                          </h1>
                          <div className="divider"></div>
                          <p className="xl:text-base font-semibold xl:pt-2 text-base text-nightblack">
                            safest <br></br>Deliveries
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="xl:w-12/12 ">
                <div className="rounded-lg">
                  <div>
                    <SliderPage />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="xl:block lg:block md:hidden sm:hidden hidden  container mx-auto xl:px-4  lg:px-4 px-4">
          <div className=" xl:px-8 lg:px-8 rounded-lg container mx-auto">
            <h1 className="xl:text-4xl lg:text-3xl text-center text-iceblue figtree">
              Trying to solve any of{" "}
              <span className="font-bold">these challenges?</span>
            </h1>
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xl:mt-12 lg:mt-8 sofia-pro">
              <div>
                <div className="xl:ml-16 lg:ml-6">
                  <div className="bg-1 rounded-md xl:p-2 p-2 speech-bubble ">
                    <p className="xl:text-lg font-semibold text-nightblack text-center text-base">
                      Largest private healthcare hospital
                    </p>
                  </div>
                </div>

                <div className="xl:mt-12 lg:mt-8">
                  <div className="bg-2 rounded-md xl:p-2 p-2 speech-bubble">
                    <p className="xl:text-lg font-semibold text-nightblack text-center text-base">
                      Top-rated pharmacy
                    </p>
                  </div>
                </div>

                <div className="xl:ml-16 xl:mt-12 lg:ml-6 lg:mt-8">
                  <div className="bg-3 rounded-md xl:p-2 p-2 speech-bubble ">
                    <p className="xl:text-lg font-semibold text-nightblack text-center text-base">
                      Advanced pediatrics specialities
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <img
                  class="xl:w-11/12 xl:ml-4 lg:w-10/12 lg:ml-4"
                  src={photoroom}
                  alt=""
                />
              </div>

              <div>
                <div className="xl:-ml-24 lg:-ml-4">
                  <div className="bg-4 rounded-md xl:p-2 p-2 speech-bubble ">
                    <p className="xl:text-lg font-semibold text-nightblack text-center text-base">
                      24/7 emergency care guarantees
                    </p>
                  </div>
                </div>

                <div className="xl:mt-12 lg:mt-8">
                  <div className="bg-3 rounded-md xl:p-2 speech-bubble p-2">
                    <p className="xl:text-lg font-semibold text-nightblack text-center text-base">
                      Book online appointment
                    </p>
                  </div>
                </div>

                <div className=" xl:mt-12 xl:-ml-24 lg:mt-8 lg:-ml-4">
                  <div className="bg-1 rounded-md xl:p-2 speech-bubble p-2">
                    <p className="xl:text-lg font-semibold text-nightblack text-center text-base">
                      Full-electric hospital beds
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        
        <section
          className="xl:block lg:block md:block sm:block block  xl:pb-16 bg-newshade16 class6 lg:pb-12 pb-12"
          style={{
            backgroundImage: `url(${testimonialnew})`,
          }}
        >
          <div className="container mx-auto xl:px-8 lg:px-8 ">
            <h1 className="xl:text-4xl font-semibold text-white xl:pt-16 text-center lg:pt-12 lg:text-3xl pt-12 text-2xl figtree">
              Testimonials
            </h1>
            <p className=" xl:block lg:block md:block sm:hidden hidden xl:text-lg text-white xl:pt-2 text-center text-lg pt-2 lg:text-lg md:text-lg text-base sofia-pro">
              Our experienced staff, modern facilities, and personalized
              approach to care set us<br></br> apart from other maternity
              hospitals, making us the best choice for expectant mothers.
            </p>

            <p className="xl:hidden lg:hidden md:hidden sm:block block pt-2 text-base text-center text-white sofia-pro px-4">Our experienced staff, modern facilities, and personalized
              approach to care set us apart from other maternity
              hospitals, making us the best choice for expectant mothers.</p>
             
            <div className="xl:mt-12 mt-12 xl:pl-0 lg:pl-0 md:pl-0 pl-8">
              <Landing/>
            </div>

            <div className="center-button xl:mt-8 mt-6">
              <a href="testimonials/">
                <button className="text-base px-8 py-2 border-box rounded-md sofia-pro text-white">View More</button>
              </a>
            </div>
          </div> 
        </section>




        <section
          className="xl:block lg:block md:block sm:block block figtree xl:h-550 "
          style={{
            backgroundImage: `url(${nurturebg1})`,
          }}
        >
          <div className="container mx-auto xl:px-4 xl:pt-4 lg:px-4 lg:pt-2 px-4 pt-2">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
              <div className="">
                <div className="">
                <div className="bg-white rounded-md xl:p-6 xl:w-10/12 lg:p-6 lg:w-10/12 md:w-12/12 w-12/12 md:p-8 p-8">
                  <div>
                    <h1 className="xl:text-3xl  text-iceblue text-center lg:text-3xl text-2xl figtree">
                      Book an <span className="font-bold">appointment</span>
                    </h1>
                    <div className="xl:mt-8 lg:mt-8 mt-2" >
                      <Form/>
                    </div>
                  </div>
                </div>
                </div>
              </div>

              <div>
                <div className="">
                  <img
                    class="xl:w-12/12 lg:w-12/12 md:w-12/12 w-12/12 xl:pt-26 lg:pt-40 md:pt-48 "
                    src={doctorbanner1}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="xl:block lg:block md:block sm:block block xl:pb-0 lg:pb-0 md:pb-12 pb-12 ">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 container mx-auto xl:px-24 xl:pt-16 lg:px-20 lg:pt-12 pt-12 px-4 gap-0">
            <div>
              <div className="">
                <img
                  class="xl:w-9/12 lg:w-10/12 md:w-9/12 w-12/12 xl:mt-0 lg:mt-0 md:mt-8 mt-0"
                  src={phoneapp}
                  alt=""
                />
              </div>
            </div>

            <div>
              <h1 className="xl:text-4xlll text-iceblue xl:pt-8 lg:text-3xl lg:pt-8 md:text-3xl text-2xl md:pt-8 pt-0 figtree">
                Download the <span className="font-bold">nurture app</span>
              </h1>
              <p
                className="xl:text-lg xl:pt-2 pt-2 text-base sofia-pro text-nightblack"
                style={{ textAlign: "justify" }}
              >
                Access video consultation with India’s top doctors on the
                nuture app. Connect with doctors online, available 24/7, from
                the comfort of your home top doctors on the nurture app top
                doctors on the nurture app.
              </p>
              <div className="flex xl:mt-8 lg:mt-4 mt-4 flex-grow">
                <div className="">
                  <div className="">
                    <img
                      className="xl:w-7/12 lg:w-7/12 md:w-36 w-32"
                      src={nurturehome9}
                      alt=""
                    />
                  </div>
                  <div className="xl:mt-4 lg:mt-4 md:mt-4 mt-2">
                    <img
                      className="xl:w-7/12 lg:w-7/12 md:w-36 w-32"
                      src={nurturehome10}
                      alt=""
                    />
                  </div>
                </div>

                <div className="xl:-ml-20 lg:-ml-24">
                  <img
                    class="xl:w-24 lg:w-22 w-20 md:w-24 xl:ml-0 lg:ml-0 ml-2"
                    src={nurtureqr}
                    alt=""
                  />
                </div>
              </div>


              
            </div>
          </div>
        </section>
 
           

        <section className="">
      <div>
      {/* Popup logic */}
      {showPopup && (
        <Login closePopup={closePopup} />
      )}
    </div>
      </section>
      </div>

      

      <Footer2 />
    </section>
  );
}
