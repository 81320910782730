import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import nurtureheader1 from "assets/img/nurtureheader1.png";
import nurturenew from "assets/img/nurturenew.png";

export default function IndexNavbar() {
  const [showMenu, setShowMenu] = useState(false);
  const [showSpecialties, setShowSpecialties] = useState(false);
  const history = useHistory(); // Use useHistory for react-router-dom v5
  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 0 }); // Navigate with state
  };

  const handleClick1 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 1 }); // Navigate with state
  };

  const handleClick2 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 2 }); // Navigate with state
  };

  const handleClick3 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 3 }); // Navigate with state
  };

  const handleClick4 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 12 }); // Navigate with state
  };

  const handleClick5 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 13 }); // Navigate with state
  };

  const handleClick6 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 15 }); // Navigate with state
  };

  const handleClick7 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 16 }); // Navigate with state
  };

  const handleClick8 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 17 }); // Navigate with state
  };

  const handleClick9 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 18 }); // Navigate with state
  };

  const handleClick10 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 19 }); // Navigate with state
  };

  const handleClick11 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 20 }); // Navigate with state
  };

  const handleClick12 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 21 }); // Navigate with state
  };

  const handleClick13 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 22 }); // Navigate with state
  };

  const handleClick14 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 23 }); // Navigate with state
  };

  const handleClick15 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 24 }); // Navigate with state
  };

  const handleClick16 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 25 }); // Navigate with state
  };

  const handleClick17 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 26 }); // Navigate with state
  };

  const handleClick18 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 27 }); // Navigate with state
  };

  const handleClick19 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    history.push("/Services", { activeToggle: 29 }); // Navigate with state
  };

  

  const handleMenuToggle = () => setShowMenu(!showMenu);

  const closeMenu = () => {
    setShowMenu(false);
    setShowSpecialties(false);
  };

  const handleBackToMenu = () => {
    // Logic to go back to the previous menu
    setShowSpecialties(false); // This hides the Specialties menu and returns to the previous menu
  };

  const handleSpecialtiesToggle = () => setShowSpecialties(!showSpecialties);

  const handleMenuItemClick = (path) => {
    history.push(path); // Use history.push for navigation
    closeMenu();
  };

  const handleBackToMenu1 = () => {
    // Logic to go back to the previous menu
    setShowSpecialties(false); // This hides the Specialties menu and returns to the previous menu
  };

  const handleSpecialtiesToggle1 = () => setShowSpecialties(!showSpecialties);

  const handleMenuItemClick1 = (path) => {
    history.push(path); // Use history.push for navigation
    closeMenu();
  };

  const [showSpecialty1Dropdown, setShowSpecialty1Dropdown] = useState(false);

  const handleSpecialty1Toggle = () => {
    setShowSpecialty1Dropdown(!showSpecialty1Dropdown);
  };

  const [showSpecialty1Dropdown1, setShowSpecialty1Dropdown1] = useState(false);

  const handleSpecialty1Toggle1 = () => {
    setShowSpecialty1Dropdown1(!showSpecialty1Dropdown1);
  };

  return (
    <section>
      <nav className="top-0 fixed z-10 w-full flex flex-wrap items-center justify-between navbar-expand-lg lg:hidden md:block block">
        <div className="bg-white overflow-hidden w-full pr-4 flex flex-wrap items-center justify-between">
          <div className="w-full relative items-center flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link to="/homepage1">
              <img
                className="w-48 md:w-48 p-2"
                src={nurturenew}
                alt="Nurture Header Logo"
              />
            </Link>
            <div
              className="text-blackk font-bold p-4 text-2xl rounded-xl cursor-pointer"
              onClick={handleMenuToggle}
            >
              ☰
            </div>
          </div>
        </div>

        {showMenu && (
          <>
            <div
              className="bg-black opacity-50 fixed top-0 left-0 w-full h-full z-40 transition-opacity duration-200 md:block hidden sm:hidden"
              onClick={closeMenu}
            ></div>

            <div
              className={`bg-white text-black fixed top-0 right-0 h-full z-50 transition-transform duration-500 ${
                showMenu ? "translate-x-0" : "translate-x-full"
              }`}
              style={{
                width: showMenu
                  ? window.innerWidth >= 768 // Checks if the viewport width is 768px or more (md and larger)
                    ? "60%" // For md and larger screens
                    : "100%" // For sm and smaller screens
                  : "0", // Sidebar hidden when menu is not shown
              }}
            >
              <div className="flex justify-between py-4 px-6 md:px-6 items-center">
                <Link to="/homepage1">
                  <img
                    className="w-40"
                    src={nurtureheader1}
                    alt="Nurture Header Logo"
                  />
                </Link>
                <div
                  onClick={closeMenu}
                  className="text-blackk font-semibold font-bold md:text-base text-base cursor-pointer md:mr-3 mr-1"
                >
                  ✕
                </div>
              </div>
              <ul className="flex flex-col py-2 md:px-6 px-4 space-y-2">
                <li className="py-4 relative">
                  <button
                    onClick={() => handleMenuItemClick("/About")}
                    className="text-base font-semibold text-blackk"
                  >
                    About
                  </button>
                </li>

                <li className="py-4 relative">
                  <button
                    className={`w-full text-left flex items-center justify-between focus:outline-none transition-colors duration-300 ${
                      showSpecialties ? "bg-white" : ""
                    }`}
                    onClick={handleSpecialtiesToggle}
                  >
                    <span className="flex-1 text-base font-semibold text-blackk">
                      <a>Specialties</a>
                    </span>
                    <span
                      className={`ml-2 text-black  transition-transform duration-300 ${
                        showSpecialties ? "transform rotate-180" : ""
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <text
                          x="50%"
                          y="50%"
                          textAnchor="middle"
                          dominantBaseline="middle"
                          fontSize="18"
                          fontFamily="Arial, sans-serif"
                        >
                          ›
                        </text>
                      </svg>
                    </span>
                  </button>
                  {showSpecialties && (
                    <div
                      className="fixed top-0 right-0 w-full h-screen bg-white z-50 transition-transform duration-500"
                      style={{
                        width: showMenu
                          ? window.innerWidth >= 768 // Checks if the viewport width is 768px or more (md and larger)
                            ? "60%" // For md and larger screens
                            : "100%" // For sm and smaller screens
                          : "0", // Sidebar hidden when menu is not shown
                      }}
                    >
                      <div className="flex flex-col h-full">
                        <div className="flex items-center justify-between bg-newshade12 p-4">
                          <span
                            className="flex items-center cursor-pointer "
                            onClick={handleBackToMenu}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-8 w-8 text-black"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <text
                                x="50%"
                                y="50%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fontSize="18"
                                fontFamily="Arial, sans-serif"
                              >
                                ‹
                              </text>
                            </svg>
                            <h1 className="ml-2 text-blackk text-lg ">
                              Nurture Hosiptals
                            </h1>
                          </span>
                          <button
                            className="text-sm font-bold text-blackk md:mr-3 mr-3"
                            onClick={handleSpecialtiesToggle}
                          >
                            ✕
                          </button>
                        </div>

                        <div className="overflow-y-auto pt-4 pl-4 pr-4">
                          <hr className="border-blue-50 " />
                          <div
                            className={`transition-all duration-300 ${
                              showSpecialty1Dropdown ? "bg-[#f7f7f7]" : ""
                            }`}
                          >
                            <button
                              className={`w-full text-left flex justify-between items-center focus:outline-none transition-all pt-2 pb-2 px-2  duration-300 ${
                                showSpecialty1Dropdown
                                  ? "bg-newshade12"
                                  : "bg-white"
                              }`}
                              onClick={handleSpecialty1Toggle}
                            >
                              <Link to="/services">
                                <h1 className="text-base text-black">
                                  Specialties
                                </h1>
                              </Link>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`h-5 w-5 transition-transform duration-300 ${
                                  showSpecialty1Dropdown
                                    ? "transform rotate-180 text-[#f7f7f7]"
                                    : "text-black"
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </button>
                            {showSpecialty1Dropdown && (
                              <div className="mt-2 text-sm px-2">
                                <a href="/Services" onClick={handleClick19}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Gynaecology
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick12}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2 ">
                                    Adolescent Health
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick11}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Family planning
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Fertility
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick5}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Ultrasonography
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick10}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Fetal Medicine
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick3}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Post Natal care
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick9}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Obstetrics
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick8}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    High Risk Pregnancy
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick7}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    ANC Care
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick4}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Laproscopic Surgeries
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick13}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Well woman clinic
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick6}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Neonatology
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick14}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Vaccination
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick15}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    NICU
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick1}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Paediatrics
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick2}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Dermatology
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick16}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Cosmetology
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick17}>
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    General Surgery
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services" onClick={handleClick18}>
                                  <p className="yellow-on-hover text-grey-600 pt-2">
                                    Urology and Urogynaecology
                                  </p>
                                </a>
                              </div>
                            )}
                          </div>
                          <hr className="border-blue-50 mt-1 " />
                        </div>

                        {/*healthchecks*/}

                        <div className=" px-4">
                          <div
                            className={`transition-all duration-300 ${
                              showSpecialty1Dropdown1 ? "bg-[#f7f7f7]" : ""
                            }`}
                          >
                            <button
                              className={`w-full text-left flex justify-between items-center focus:outline-none transition-all pt-2 pb-2 px-2  duration-300 ${
                                showSpecialty1Dropdown1
                                  ? "bg-newshade12"
                                  : "bg-white"
                              }`}
                              onClick={handleSpecialty1Toggle1}
                            >
                              <Link to="/services">
                                <h1 className="text-base text-black">
                                  Health Checks
                                </h1>
                              </Link>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`h-5 w-5 transition-transform duration-300 ${
                                  showSpecialty1Dropdown1
                                    ? "transform rotate-180 text-[#f7f7f7]"
                                    : "text-black"
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </button>
                            {showSpecialty1Dropdown1 && (
                              <div className="mt-2 text-sm px-2">
                                <a href="/Services">
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Master Health check-up – Female
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services">
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2 ">
                                    Executive Health Check-up - Female
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services">
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Pre-Marital Package - Female
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services">
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Pre-Conception - Female
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services">
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Well Woman Package
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services">
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Executive Health Check-up - Male
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services">
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Pre-Marital Package - Male
                                  </p>
                                </a>
                                <hr className="border-blue-50 mt-2" />
                                <a href="/Services">
                                  <p className="yellow-on-hover text-grey-600 pt-2 pb-2">
                                    Adolescent Health Check-up
                                  </p>
                                </a>
                              </div>
                            )}
                          </div>
                          <hr className="border-blue-50 mt-1 " />
                        </div>
                      </div>
                    </div>
                  )}
                </li>

                <li className="py-4">
                  <button
                    onClick={() => handleMenuItemClick("/ourteam")}
                    className="text-base font-semibold text-blackk"
                  >
                    Doctors
                  </button>
                </li>

                <li className="py-4">
                  <button
                    onClick={() => handleMenuItemClick("/testimonials")}
                    className="text-base font-semibold text-blackk"
                  >
                    Testimonials
                  </button>
                </li>
                <li className="py-4">
                  <button
                    onClick={() => handleMenuItemClick("/gallery")}
                    className="text-base font-semibold text-blackk"
                  >
                    Gallery
                  </button>
                </li>
                <li className="py-4">
                  <button
                    onClick={() => handleMenuItemClick("/contact?")}
                    className="text-base font-semibold text-blackk"
                  >
                    Contact
                  </button>
                </li>
              </ul>
            </div>
          </>
        )}
      </nav>
    </section>
  );
}
