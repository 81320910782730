import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import Footer2 from "components/Footers/Footer2";
import google from "assets/img/google.png";

export default function Testimonials() {
  return (
    <section>
      <Indexnavbar2/>
      <section className="xl:block lg:block md:block sm:block block container mx-auto px-4">
        <h1 className="text-iceblue figtree xl:text-4xl lg:text-3xl text-3xl text-center font-bold xl:pt-12 pt-12">
          Testimonials
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 xl:mt-12 mt-12 xl:pb-16 pb-16 ">
          <div>
            <div className="bg-6 ">
              <div className="xl:w-12/12 xl:p-6 lg:w-12/12 lg:p-6 border-12 md:w-12/12 md:p-6 w-12/12 p-6  flex flex-col justify-between rounded-md">
                <p
                  className="sofia-pro xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                  style={{ textAlign: "justify" }}
                >
                  Nurture Never felt like a hospital. Dr.Ambuja Govidraju is a
                  wonderful kind and very friendly doctor..listens to our
                  problems camly and respond to us.Having a high risk pregnancy,
                  I was feeling tensed through out my pregnancy but the
                  treatment in nurture and doctor's hope made everything go
                  smoothly and by god's grace I was able to have my little
                  princess in my hand .I am grateful to Dr.Ambuja mam.I am
                  really short of words to express my gratitude. Dr.Bhagyshree,
                  pediatrician takes such gud care for newborns and guide us in
                  every aspect which makes us feel easy. All the nursing staff
                  are very professional and cooperative. Front office staff are
                  so kind, they don't make patients to wait.They finish all the
                  formalities without any hassle.Big Thanks to security guards
                  and house keeping staff for keeping hospital so clean and
                  hygiene and must say hospital is so well maintained. I would
                  really recommend nurture to everyone who are seeking maternity
                  and pediatric care.
                </p>
                <div className="flex justify-between items-center mt-4">
                  <div>
                  <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                    MV G
                  </h4>
                  <p className="text-xl text-yellow1 ">★★★★★</p>
                  </div>
                  <img
                    src={google}
                    className="w-24"
                    alt="Web-Development-Company-Bangalore"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 bg-6">
              <div className="xl:w-12/12 xl:p-6 lg:w-12/12 lg:p-6 border-12 md:w-12/12 md:p-6 w-12/12 p-6  flex flex-col justify-between rounded-md">
                <p
                  className="poppins xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                  style={{ textAlign: "justify" }}
                >
                  Dr. Ambuja madam, a gynecologist, took excellent care of me
                  during my pregnancy and delivery. The pregnancy period was
                  made easier with her helpful tips and advice. The hospital
                  maintained high standards of hygiene, and the staff were all
                  very professional and supportive. Overall, I had a positive
                  experience.
                </p>
                <div className="flex justify-between items-center mt-4">
                  <div>
                  <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                    Pallavi Prabhu
                  </h4>
                  <p className="text-xl text-yellow1 ">★★★★★</p>
                  </div>
                  <img
                    src={google}
                    className="w-24"
                    alt="Web-Development-Company-Bangalore"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 bg-6">
              <div className="xl:w-12/12 xl:p-6 lg:w-12/12 lg:p-6 border-12 md:w-12/12 md:p-6 w-12/12 p-6  flex flex-col justify-between rounded-md">
                <p
                  className="poppins xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                  style={{ textAlign: "justify" }}
                >
                  The best hospital with affordable price with great ambience
                  and interior designs general, deluxe and suite rooms ,with
                  well equipped and hygiene maintained , great hospitality with
                  good caring for mother and child given By Dr Ambhuja madam and
                  team Thank you Ambhuja madam for your support 🙏
                </p>
                <div className="flex justify-between items-center mt-4">
                  <div>
                  <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                    Goutham Anand
                  </h4>
                  <p className="text-xl text-yellow1 ">★★★★★</p>
                  </div>
                  <img
                    src={google}
                    className="w-24"
                    alt="Web-Development-Company-Bangalore"
                  />
                </div>
              </div>
            </div>


            <div className="mt-4 bg-6">
              <div className="xl:w-12/12 xl:p-6 lg:w-12/12 lg:p-6 border-12 md:w-12/12 md:p-6 w-12/12 p-6  flex flex-col justify-between rounded-md">
                <p
                  className="poppins xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                  style={{ textAlign: "justify" }}
                >
                  I got to know about nurture hospital through my gynaecologist, Dr Padmapriya. We visited the hospital and was sure to get our delivery done here. Hospital is clean, well maintained and friendly staff. All medical staff are professional and very cooperative. I have visited this hospital for multiple scans, they were very interactive and kept track of my progress. We had a good stay at the hospital. Rooms were cleaned frequently and had all amenities available. Medical staff visited us frequently to check if any help was needed. Big thanks to the medical staff, hospital staff, housekeeping staff, security guards for all they put to make the stay perfect. Hospital staff also helped us in insurance and birth certificates, was done quickly without any hassle.
                  Highly recommend this hospital for all. They make sure to make your stay memorable.
                </p>
                <div className="flex justify-between items-center mt-4">
                  <div>
                  <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                  Vagdevi Ranganatha
                  </h4>
                  <p className="text-xl text-yellow1 ">★★★★★</p>
                  </div>
                  <img
                    src={google}
                    className="w-24"
                    alt="Web-Development-Company-Bangalore"
                  />
                </div>
              </div>
            </div>
          </div>

          {/*2nd div*/}
          <div>
            <div className="bg-6">
              <div className="xl:w-12/12 xl:p-6 lg:w-12/12 lg:p-6 border-12 md:w-12/12 md:p-6 w-12/12 p-6  flex flex-col justify-between rounded-md">
                <p
                  className="poppins xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                  style={{ textAlign: "justify" }}
                >
                  Nurture - As the name suggests, they nurture feelings of care,
                  trust, hospitality and a feeling of being with family like in
                  one's home! Our primary consultant was Doc.Latha who along
                  with Dr.Ranganath helped my wife Sneha with a normal birth
                  (unheard of these days ) of our baby Kushi at nurture. What
                  made it even more memorable was that Kushi was the 100th baby
                  born at nurture! Right from the moment you enter nurture, you
                  feel warm and welcomed by the front office team. Without being
                  pushy, they showed us around the different kinds of rooms
                  available with a smile. We were undecided and had finalized
                  another major corporate hospital close by with a feeling that
                  they maybe be better equipped in case of an emergency.
                  However, better sense prevailed (with a hint provided by
                  Doc.Latha) and we chose nurture in the end. It was the best
                  choice we made - Doc. Ambuja made it a point to meet the
                  family even though she was not the primary consultant and made
                  us feel assured and at ease. The rooms are spacious, clean and
                  the nursing team is always happy to help and serve with a
                  smile. Even though the hospital was not tied up with an
                  insurance provider for cashless claims, the documentation they
                  provided enabled a seamless and quick reimbursement of the
                  expenses incurred. All in all, I would wholeheartedly
                  recommend nurture to any expecting parents! It is these
                  smaller lesser known establishments that have a human touch
                  that we all yearn for at the end of the day! Thank you, team
                  nurture :)
                </p>
                <div className="flex justify-between items-center mt-4">
                  <div>
                  <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                    Keshav Raj Tammineedi
                  </h4>
                  <p className="text-xl text-yellow1 ">★★★★★</p>
                  </div>
                  <img
                    src={google}
                    className="w-24"
                    alt="Web-Development-Company-Bangalore"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 bg-6">
              <div className="xl:w-12/12 xl:p-6 lg:w-12/12 lg:p-6 border-12 md:w-12/12 md:p-6 w-12/12 p-6  flex flex-col justify-between rounded-md">
                <p
                  className="poppins xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                  style={{ textAlign: "justify" }}
                >
                  I came to know this hospital by our doctor Latha J .The
                  services were good and well maintained the staff and doctors
                  were excellent in all ways. Thanks, everything was good. Very
                  good nursing department and the doctors are very friendly to
                  us. Best hospital around. Highly recommend.
                </p>
                <div className="flex justify-between items-center mt-4">
                  <div>
                  <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                    arpitha s
                  </h4>
                  <p className="text-xl text-yellow1 ">★★★★★</p>
                  </div>
                  <img
                    src={google}
                    className="w-24"
                    alt="Web-Development-Company-Bangalore"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 bg-6">
              <div className="xl:w-12/12 xl:p-6 lg:w-12/12 lg:p-6 border-12 md:w-12/12 md:p-6 w-12/12 p-6  flex flex-col justify-between rounded-md">
                <p
                  className="poppins xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                  style={{ textAlign: "justify" }}
                >
                  Dr.Ambuja Mam She Is A Perfect Blend Of Good Friendly Human
                  Being And A Best Doctor Thank You And Your Team For All The
                  Kind Support And Everything. Very Co-operative Staff And Very
                  Good At Hospital Service Environment Communication Exceptional
                  Care With You In Our Corner Our Labor And Delivery Was
                  Incredible. Once Again Thanks To The Whole nurture Family For
                  Being Amazing Part Of Our Journey...❤️
                </p>
                <div className="flex justify-between items-center mt-4">
                  <div>
                  <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                    Supritha Gowda
                  </h4>
                  <p className="text-xl text-yellow1 ">★★★★★</p>
                  </div>
                  <img
                    src={google}
                    className="w-24"
                    alt="Web-Development-Company-Bangalore"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-6">
              <div className="xl:w-12/12 xl:p-6 lg:w-12/12 lg:p-6 border-12 md:w-12/12 md:p-6 w-12/12 p-6  flex flex-col justify-between rounded-md">
                <p
                  className="poppins xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                  style={{ textAlign: "justify" }}
                >
                  My experience at nurture Hospital Rajajinagar has been nothing
                  short of exceptional. From confirming my wife's pregnancy to
                  the delivery, Dr. Ambuja Govindraj and her team provided
                  outstanding care every step of the way. Dr. Ambuja's warmth
                  and expertise made us feel secure throughout the journey. The
                  hospital boasts world-class facilities, including in-house
                  scanning, lab tests, pharmacy, and operation theaters. The
                  yoga rooms for pregnant mothers were a thoughtful addition.
                  The cafeteria offers delicious and nutritious meals. Dr.
                  Rajeshwari, in pediatrics, deserves special mention for her
                  exceptional care of our child. The accommodation options are
                  excellent, with private AC, twin sharing, and suite rooms, all
                  well-equipped and maintained for hygiene. The staff went above
                  and beyond to celebrate our baby's birth with a cake cutting
                  ceremony and a candlelight dinner before discharge. They even
                  decorated our car for the journey home. Furthermore, their
                  tie-ups with First Cry and Seba Med ensured we received
                  thoughtful goodies. nurture Hospital Rajajinagar is more than
                  just a medical facility; it's a place where care, comfort, and
                  celebration intersect. I highly recommend it to anyone seeking
                  top-notch maternity and pediatric care.
                </p>
                <div className="flex justify-between items-center mt-4">
                  <div>
                  <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                    Anand Badiger
                  </h4>
                  <p className="text-xl text-yellow1 ">★★★★★</p>
                  </div>
                  <img
                    src={google}
                    className="w-24"
                    alt="Web-Development-Company-Bangalore"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 bg-6">
              <div className="xl:w-12/12 xl:p-6 lg:w-12/12 lg:p-6 border-12 md:w-12/12 md:p-6 w-12/12 p-6  flex flex-col justify-between rounded-md">
                <p
                  className="poppins xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                  style={{ textAlign: "justify" }}
                >
                  🙏Thank u Ambuja mam for taking a quick action on my sister's
                  condition, during our helpless situation. We can't thank u
                  enough ❤️ also not to miss the staffs, they were
                  polite,concerned and attentive enough to our sister and to our
                  family's emotional situation. Thank u nurture team 🙏❤️
                </p>
                <div className="flex justify-between items-center mt-4">
                  <div>
                  <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                    Shwetha Gowda
                  </h4>
                  <p className="text-xl text-yellow1 ">★★★★★</p>
                  </div>
                  <img
                    src={google}
                    className="w-24"
                    alt="Web-Development-Company-Bangalore"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 bg-6">
              <div className="xl:w-12/12 xl:p-6 lg:w-12/12 lg:p-6 border-12 md:w-12/12 md:p-6 w-12/12 p-6  flex flex-col justify-between rounded-md">
                <p
                  className="poppins xl:text-sm text-nightblack lg:text-sm md:text-sm text-sm"
                  style={{ textAlign: "justify" }}
                >
                  1st thing about the hospital i can rate is 5 out of 5. My wife
                  got discharged today, my wife was really happy with Dr. Ambuja
                  mam, the way Mam talks and handled my wife like there own
                  sister which feel my wife more safe and comfortable. Nursing
                  staff took good care of my baby and wife. Housekeeping also
                  they maintain time to time cleaning and hygiene of rooms. At
                  the time of hospital entry there should be smiley face,
                  ofcourse reception staff maintain that.. And taking care of
                  billing fast, where they dont make us to wait. Security also
                  the way they greet at the time of entry and exit, we feel very
                  happy and comfortable.. Complimentary photo shoot and goodies,
                  car decoration at the time of discharge made us very special.
                  Im really thankful to Dr. Ambuja Mam and Dr. Padmapriya and
                  nurture staff. Overall its highly appreciated and
                  recommended.
                </p>
                <div className="flex justify-between items-center mt-4">
                  <div>
                  <h4 className="poppins xl:text-base text-iceblue font-semibold lg:text-sm md:text-sm text-sm">
                    chethan devraj
                  </h4>
                  <p className="text-xl text-yellow1 ">★★★★★</p>
                  </div>
                  <img
                    src={google}
                    className="w-24"
                    alt="Web-Development-Company-Bangalore"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2/>
    </section>
    
  );
}
